import { useContext } from 'react';

import { AnonymousContext } from '../context/anonymous-comments';

export const useAnonymousComments = () => {
  const context = useContext(AnonymousContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useAnonymousContext was used outside of its Provider');
  }

  return context;
};
