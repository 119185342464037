import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import StyledInput from '../common/form/styled-input';
import CommonButtonDefault from '../common/buttons/default';
import { strings } from '../../localization/strings';
import { updatePassword } from '../../services/api/users';
import StyledError from '../common/form/styled-error';
import AlertPrimary from '../common/alerts/alert-primary';
import CommonAnimatedSpinner from '../common/animated/spinner';

interface Inputs {
  confirmNewPassword: string;
  newPassword: string;
  oldPassword: string;
  common: string;
}

interface ComponentProps {
  token: string;
}

export default function ChangePasswordForm({ token }: ComponentProps) {
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);
      const res = await updatePassword(token, data.oldPassword, data.newPassword, data.confirmNewPassword);

      if (res) {
        setSuccess(true);
        reset();
      }
      setIsLoading(false);
    } catch (e) {
      if (e instanceof Error) {
        setIsLoading(false);
        setError('common', { message: e.message });
      }
    }
  };

  return isloading ? (
    <div className="py-5 flex align-middle justify-center">
      <CommonAnimatedSpinner />
    </div>
  ) : (
    <div className="py-7">
      <div className="mb-6">
        <h2 className="text-base font-semibold leading-7 ">{strings.profile.settingsPage.changePassword.title}</h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          {strings.profile.settingsPage.changePassword.text}.{' '}
          <CommonButtonDefault link onClick={() => setShowForm((current) => !current)}>
            {strings.profile.settingsPage.changePassword.linkText}
          </CommonButtonDefault>
        </p>
      </div>

      {success && <AlertPrimary type="success" title={strings.profile.settingsPage.changePassword.alertSuccess} />}
      {errors.common && <AlertPrimary type="danger" title={errors.common.message} />}
      {showForm && (
        <form className="md:col-span-2" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:max-w-xl">
            <div className="col-span-full">
              <label htmlFor="current-password" className="block text-sm font-medium leading-6 ">
                {strings.form.oldPassword}
              </label>
              <div className="mt-2">
                <StyledInput
                  id="current-password"
                  type="password"
                  autoComplete="current-password"
                  className={` ${errors.oldPassword && `!border-red-500`} `}
                  {...register('oldPassword', { required: true })}
                />
                {errors.oldPassword && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="new-password" className="block text-sm font-medium leading-6 ">
                {strings.form.newPassword}
              </label>
              <div className="mt-2">
                <StyledInput
                  id="new-password"
                  type="password"
                  autoComplete="new-password"
                  className={` ${errors.newPassword && `!border-red-500`} `}
                  {...register('newPassword', { required: true })}
                />
                {errors.newPassword && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 ">
                {strings.form.repeatPassword}
              </label>
              <div className="mt-2">
                <StyledInput
                  id="confirm-password"
                  type="password"
                  autoComplete="confirm-password"
                  className={` ${errors.confirmNewPassword && `!border-red-500`} `}
                  {...register('confirmNewPassword', { required: true })}
                />
                {errors.confirmNewPassword && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
              </div>
            </div>
          </div>

          <div className="mt-6">
            <CommonButtonDefault primary xl type="submit">
              {strings.button.save}
            </CommonButtonDefault>
          </div>
        </form>
      )}
    </div>
  );
}
