import React, { useState } from 'react';

import { useComments } from '../../hooks/use-comments';
import CommonButtonDefault from '../common/buttons/default';
import * as commentsApi from '../../services/api/comments';
import { pluralizeWithNumber } from '../../services/grammar/pluralize';
import { strings } from '../../localization/strings';
import CommonAnimatedSpinner from '../common/animated/spinner';
import AlertPrimary from '../common/alerts/alert-primary';
import { useAuth } from '../../hooks/use-auth';
import { useAnonymousComments } from '../../hooks/use-anonymous-comments';

interface ComponentProps {
  repliesCount: number;
}

function CommentsRepliesLoaderComponent({ repliesCount }: ComponentProps) {
  const { comments, parentId, article, setComments } = useComments();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [repliesFiltered, setRepliesFiltered] = useState<number>(0);
  const { token } = useAuth();
  const { showAnonymousComments } = useAnonymousComments();
  const loadReplies = async () => {
    if (article) {
      try {
        setIsLoading(true);
        const replies = await commentsApi.getComments(token, article?.id, parentId);

        const repliesWUser = replies.filter((item) => item.userId);

        if (showAnonymousComments) {
          setComments(replies);
        } else if (repliesWUser) {
          setRepliesFiltered(repliesWUser.length);
          setComments(repliesWUser);
        }

        setIsLoading(false);
      } catch (e) {
        setError((e as Error).message);
      }
    }
  };

  if (
    (showAnonymousComments && repliesCount > 0 && comments.length <= 0) ||
    (!showAnonymousComments && repliesFiltered > 0 && comments.length <= 0)
  ) {
    return (
      <>
        {error && <AlertPrimary text={error} type="danger" />}
        <CommonButtonDefault linShowMore onClick={() => loadReplies()}>
          <i className="icon-arrow-turn-down-right mr-1" />
          <span className="hover:underline underline-offset-1">
            {strings.comments.show} {pluralizeWithNumber(repliesCount, strings.comments.replies)}
          </span>
          {isLoading && (
            <span className="inline-block mt-2">
              <CommonAnimatedSpinner />
            </span>
          )}
        </CommonButtonDefault>
      </>
    );
  }

  return null;
}

export default CommentsRepliesLoaderComponent;
