import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { getExternalArticle } from '../../services/api/articles';
import { Article } from '../../entities/article';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import { CommentsContextProvider } from '../../context/comments';
import { getComments } from '../../services/api/comments';
import CommentsListComponent from '../../components/comments/comments-list';
import { useAuth } from '../../hooks/use-auth';
import { requestIframeResize } from '../../services/post-message';
import useWindowDimensions from '../../hooks/use-window-dimensions';

export default function CommentsFullComponent() {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [article, setArticle] = useState<Article>();

  const height = useWindowDimensions();

  const { token } = useAuth();
  const { articleId, portal } = useParams();
  const [queryParams] = useSearchParams();

  useEffect(() => {
    try {
      const fetchArticle = async () => {
        if (articleId && portal) {
          setIsLoading(true);

          const item = await getExternalArticle(token, portal, articleId);
          const comments = await getComments(token, item.id);

          setIsLoading(false);

          item.comments = comments;

          setArticle(item);
        }
      };

      fetchArticle();
    } catch (e) {
      setIsLoading(false);
      setError((e as Error).message);
    }
  }, [articleId, portal, token]);

  useEffect(() => {
    requestIframeResize(queryParams.get('targetElementId'));
  }, [height, queryParams]);

  return (
    <div>
      {error && (
        <div className="container max-w-4xl px-4 mx-auto pb-10 mt-16">
          <AlertPrimary text={error} type="danger" />
        </div>
      )}
      {isLoading && (
        <div className="py-5 flex align-middle justify-center">
          <CommonAnimatedSpinner />
        </div>
      )}
      {article && (
        <div className="border-[3px] border-[#E2EAEE] rounded-[10px] bg-white py-4 px-5 leading-tight text-xs">
          <CommentsContextProvider comments={article?.comments} article={article}>
            <CommentsListComponent level={0} isInList={false} isFullComponent />
          </CommentsContextProvider>
        </div>
      )}
    </div>
  );
}
