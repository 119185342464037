import React from 'react';

import CommentsFormComponent from './comments-form';
import { useComments } from '../../hooks/use-comments';
import { CommentsContextProvider } from '../../context/comments';
import CommentComponent from './comment';
import CommentsLoaderComponent from './comments-loader';
import { parameters } from '../../constants/parameters';
import CommentsRepliesLoaderComponent from './replies-loader';
import { Comment } from '../../entities/comment';
import { useAnonymousComments } from '../../hooks/use-anonymous-comments';

interface ComponentProps {
  level: number;
  isInList?: boolean;
  // hides reply/comment functionality, changes design
  isShortComponent?: boolean;
  isFullComponent?: boolean;
  isReported?: boolean;
}

function CommentsListComponent({ level, isInList, isShortComponent, isReported, isFullComponent }: ComponentProps) {
  const { comments, article, updateComment, removeComment } = useComments();
  const { showAnonymousComments } = useAnonymousComments();

  const onCommentUpdated = (comment: Partial<Comment>, id: string) => {
    updateComment(id, comment);
  };

  const onCommentDeleted = (item: Comment) => {
    removeComment(item);
  };

  return (
    <div>
      {/* <div>total comments in list: {comments.length}</div> */}
      {/* visi komentarai iš straipsnio */}
      {/* 	jeigu komentaras turi repliesCount > 0, Show replies užloadina childus	 */}
      {/* 	jeigu paspaudi reply, tai parodo apatinę formą	 */}
      {/* 		CommentsList su child komentarais */}
      {comments &&
        comments.map((item, index) => (
          <div key={`comments-${index + 1}`} className="mb-6">
            {level < parameters.maxCommentsDepth ? (
              <CommentsContextProvider comments={item.childs} article={article} parentId={item.id}>
                <CommentComponent
                  isShortComponent={isShortComponent}
                  comment={item}
                  update={(comment) => onCommentUpdated(comment, item.id)}
                  deleteComment={() => onCommentDeleted(item)}
                />
                {/* <div className="text-xs text-gray-400"> */}
                {/*   ID:{item.id} */}
                {/*   <br /> */}
                {/*   parent:{item.parentId} */}
                {/*   <br /> */}
                {/*   childs:{item.childs.length} */}
                {/* </div> */}
                {!isShortComponent && (
                  <CommentsRepliesLoaderComponent
                    repliesCount={showAnonymousComments ? item.repliesCount : item.repliesCountLogged}
                  />
                )}
                <div className="pl-5 border-l border-[#EAEDF0] space-y-4 my-2">
                  <CommentsListComponent level={level + 1} />
                </div>
              </CommentsContextProvider>
            ) : (
              <CommentComponent
                comment={item}
                update={(comment) => onCommentUpdated(comment, item.id)}
                deleteComment={() => onCommentDeleted(item)}
              />
            )}
          </div>
        ))}
      {!isShortComponent && !isReported && <CommentsLoaderComponent level={level} isList={isInList || false} />}
      {article && !isReported && !isShortComponent && (
        <CommentsFormComponent isFullComponent={isFullComponent} level={level} />
      )}
    </div>
  );
}

CommentsListComponent.defaultProps = {
  isInList: false,
  isShortComponent: false,
  isReported: false,
  isFullComponent: false,
};

export default CommentsListComponent;
