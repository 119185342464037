export const requestIframeResize = (elementId?: string | null) => {
  if (window.parent) {
    window.parent.postMessage(
      {
        type: 'resize',
        target: elementId,
        height: window.document.body.scrollHeight,
      },
      '*',
    );
  }
};

export const requestDelfiToken = () => {
  if (window.parent) {
    window.parent.postMessage(
      {
        type: 'requestDelfiToken',
      },
      '*',
    );
  }
};
