import LOGOTYPE from './images/napo.svg';
import DELFI from './images/icons/icon-delfi.svg';
import FB from './images/icons/icon-fb.svg';
import GOOGLE from './images/icons/icon-google.svg';
import AVATAR from './images/icons/noavatar.jpg';
import REGISTER from './images/modal/register.svg';
import REGISTERANONYMOUS from './images/modal/register-anonymous.svg';
import DELFIPLUS from './images/icons/icon-delfi+.svg';

const IMAGES = {
  LOGOTYPES: {
    PRIMARY: LOGOTYPE,
  },
  AVATAR,
  LOGOS: {
    DELFI,
    FB,
    GOOGLE,
  },
  MODAL: {
    REGISTERANONYMOUS,
    REGISTER,
  },
  ICON: {
    DELFIPLUS,
  },
};

export default IMAGES;
