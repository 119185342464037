import { styled } from 'twin.macro';
import { up } from 'styled-breakpoints';

const ResponsiveUtilitity = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  background: #c00;
  padding: 2px 3px;
  color: #fff;
  &:before {
    content: 'xs';

    ${up('sm')} {
      content: 'sm';
    }

    ${up('md')} {
      content: 'md';
    }

    ${up('lg')} {
      content: 'lg';
    }

    ${up('xl')} {
      content: 'xl';
    }

    ${up('xxl')} {
      content: 'xxl';
    }
  }
`;

export default ResponsiveUtilitity;
