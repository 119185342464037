import React from 'react';

import { strings } from '../../localization/strings';
import CommonButtonDefault from '../common/buttons/default';
import { ModalKeys } from '../../context/modals';
import { useModal } from '../../hooks/use-modal';

export default function DeleteUserComponent() {
  const { setVisible } = useModal();

  const onOpenModal = (key: string) => {
    setVisible(key, true);
  };

  return (
    <div className="py-7">
      <div className="mb-6">
        <h2 className="text-base font-semibold leading-7 ">{strings.profile.settingsPage.deleteAccount.title}</h2>
        <div className="grid grid-cols-1 md:grid-cols-12">
          <div className="col-span-9">
            <p className="text-sm text-gray-400">{strings.profile.settingsPage.deleteAccount.text}</p>
          </div>
          <div className="col-span-3 md:text-right mt-4 md:mt-0">
            <CommonButtonDefault className="!text-sm" delete onClick={() => onOpenModal(ModalKeys.DeleteUser)}>
              <i className="icon icon-trash mr-2" />
              <span className="underline underline-offset-4 hover:no-underline">
                {strings.profile.settingsPage.deleteAccount.buttonDelete}
              </span>
            </CommonButtonDefault>
          </div>
        </div>
      </div>
    </div>
  );
}
