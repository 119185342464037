import axios, { AxiosError } from 'axios';

import { AuthToken } from '../../entities/auth-token';
import { parameters } from '../../constants/parameters';
import { Customer } from '../../entities/customer';
import { RegistrationData } from '../../entities/registration-data';
import { StatusResponse } from '../../entities/status-response';
import UploadedFile from '../../entities/uploaded-file';
import { CustomerRole } from '../../entities/customer-role';

export const register = async (data: RegistrationData): Promise<AuthToken> => {
  const url = `${parameters.api.baseUrl}/users/register`;

  const result = await axios.post(url, data);

  return {
    token: result.data.token,
    expiredAt: new Date(result.data.expiredAt),
  };
};

export const login = async (email: string, password: string): Promise<AuthToken> => {
  const url = `${parameters.api.baseUrl}/users/login`;

  try {
    const result = await axios.post(url, {
      email,
      password,
    });

    return {
      token: result.data.token,
      expiredAt: new Date(result.data.expiredAt),
    };
  } catch (e) {
    const err = e as AxiosError;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};

export const requestPasswordReset = async (email: string): Promise<AuthToken> => {
  const url = `${parameters.api.baseUrl}/users/request-password-reset`;

  try {
    const result = await axios.post(url, {
      email,
    });

    return result.data.status;
  } catch (e) {
    const err = e as AxiosError;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};

export const validatePasswordReset = async (token: string): Promise<Customer> => {
  const url = `${parameters.api.baseUrl}/users/validate-password-token/${token}`;

  try {
    const result = await axios.get(url);

    return result.data;
  } catch (e) {
    const err = e as AxiosError;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};

export const completePasswordReset = async (token: string, password: string): Promise<StatusResponse> => {
  const url = `${parameters.api.baseUrl}/users/complete-password-reset`;

  try {
    const result = await axios.post(url, {
      token,
      password,
    });

    return result.data.status;
  } catch (e) {
    const err = e as AxiosError;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};

export const current = async (token: string): Promise<Customer> => {
  const url = `${parameters.api.baseUrl}/users/current`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const updateCurrentUser = async (token: string, data: Partial<Customer>): Promise<Customer> => {
  const url = `${parameters.api.baseUrl}/users/profile/update`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  } catch (e) {
    const err = e as AxiosError;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};

export const updatePassword = async (
  token: string,
  oldPassword: string,
  newPassword: string,
  confirmNewPassword: string,
): Promise<Customer> => {
  const url = `${parameters.api.baseUrl}/users/profile/change-password`;

  try {
    const result = await axios.post(
      url,
      {
        oldPassword,
        newPassword,
        confirmNewPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return result.data;
  } catch (e) {
    const err = e as AxiosError;

    throw new Error(err.response?.data.error);
  }
};

export const deleteCurrentUser = async (token: string, confirmation: string): Promise<string> => {
  const url = `${parameters.api.baseUrl}/users/profile/delete`;

  try {
    const result = await axios.post(
      url,
      { confirmation },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return result.data;
  } catch (e) {
    const err = e as AxiosError;

    throw new Error(err.response?.data.error);
  }
};

export const uploadFile = async (token: string): Promise<UploadedFile> => {
  const url = `${parameters.api.baseUrl}/users/profile/image-upload-url`;

  try {
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  } catch (e) {
    const err = e as AxiosError;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};

export const getUser = async (token: string, id: string): Promise<Customer> => {
  const url = `${parameters.api.baseUrl}/users/get/${id}`;

  try {
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  } catch (e) {
    const err = e as AxiosError;

    throw new Error(err.response?.data.error);
  }
};

export const listByRole = async (token: string): Promise<CustomerRole[]> => {
  const url = `${parameters.api.baseUrl}/users/by-role`;

  try {
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  } catch (e) {
    const err = e as AxiosError;

    throw new Error(err.response?.data.error);
  }
};

export const updateUser = async (token: string, id: string, data: Partial<Customer>): Promise<Customer> => {
  const url = `${parameters.api.baseUrl}/users/update/${id}`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  } catch (e) {
    const err = e as AxiosError;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};

export const changeBlockStatus = async (token: string, id: string, status: number): Promise<Customer> => {
  const url = `${parameters.api.baseUrl}/users/block/${id}`;

  try {
    const result = await axios.post(
      url,
      { status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return result.data;
  } catch (e) {
    const err = e as AxiosError;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};

export const getSocialLoginUrl = async (token: string, network: string): Promise<string> => {
  const url = `${parameters.api.baseUrl}/users/social/connect/${network}`;

  try {
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data.loginUrl;
  } catch (e) {
    const err = e as AxiosError;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};

export const search = async (token: string, keyword: string): Promise<Customer[]> => {
  const url = `${parameters.api.baseUrl}/users/search?keyword=${keyword}`;

  try {
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  } catch (e) {
    const err = e as AxiosError;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};
