import React, { createContext, useState, useCallback, useMemo } from 'react';

interface ComponentProps {
  children: React.ReactNode;
}

interface ContextValue {
  isPageNotFound: boolean;
  setPageNotFoundState: (state: boolean) => void;
}

const LayoutContext = createContext<ContextValue>({
  isPageNotFound: false,
  setPageNotFoundState: () => null,
});

function LayoutContextProvider({ children }: ComponentProps) {
  const [isPageNotFound, setPageNotFound] = useState<boolean>(false);

  const setPageNotFoundState = useCallback(
    (state = true) => {
      setPageNotFound(state);
    },
    [setPageNotFound],
  );

  const contextValue = useMemo(
    () => ({
      setPageNotFoundState,
      isPageNotFound,
    }),
    [isPageNotFound, setPageNotFoundState],
  );

  return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>;
}

export { LayoutContext, LayoutContextProvider };
