import React from 'react';
import tw, { styled } from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { parameters } from '../../../constants/parameters';
import IMAGES from '../../../assets/images';
import HeadingHero from '../headings/headingHero';
import { strings } from '../../../localization/strings';
import CommonButtonDefault from '../buttons/default';
import { useModal } from '../../../hooks/use-modal';

export default function RegisterBenefits() {
  const navigate = useNavigate();
  const { setVisible } = useModal();

  const TextContainer = styled.div<{ position: number }>`
    ${tw`px-3.5 py-1 text-sm rounded border border-nIndigo-100 bg-nIndigo-20 min-h-[50px] flex items-center w-full relative`};
    ${(props) => (props.position === 0 ? tw`md:w-[180px] flex-shrink-0` : tw`md:w-auto`)}
  `;

  const IconContainer = styled.div<{ position: number }>`
    ${tw`p-1 h-6 w-6 text-xs rounded-full border text-white border-white flex justify-center items-center absolute right-[-12px] top-[-12px]`};
    ${(props) => props.position === 0 && tw`bg-[#1777FF]`};
    ${(props) => props.position === 1 && tw`bg-[#008F88]`};
    ${(props) => props.position === 2 && tw`bg-[#FBBC05]`};

    -webkit-box-shadow: 0px 4px 8px 0px rgba(214, 226, 234, 1);
    -moz-box-shadow: 0px 4px 8px 0px rgba(214, 226, 234, 1);
    box-shadow: 0px 4px 8px 0px rgba(214, 226, 234, 1);
  `;

  const onRegisterClick = () => {
    setVisible('Register', false);
    navigate(parameters.registerPageUrl);
  };

  return (
    <div>
      <div className="flex justify-center mt-4 mb-7">
        <img src={IMAGES.MODAL.REGISTER} alt="" aria-hidden="true" />
      </div>
      <div>
        <HeadingHero>{strings.modalRegister.title}</HeadingHero>
        <div className="md:flex md:justify-center space-y-4 md:space-y-0 md:space-x-6 md:items-stretch">
          {strings.modalRegister.benefits.map((benefit, index) => (
            <TextContainer position={index} key={index}>
              {benefit}
              <IconContainer
                className={classNames({
                  'icon-comment': index === 0,
                  'icon-edit-outline': index === 1,
                  'icon-category-style': index === 2,
                })}
                position={index}
              />
            </TextContainer>
          ))}
        </div>
        <CommonButtonDefault
          wide
          xl
          primary
          className="mt-5 md:mt-7 mx-auto"
          type="submit"
          onClick={() => onRegisterClick()}
        >
          {strings.modalRegister.button}
        </CommonButtonDefault>
        <div className="mt-4 text-center text-xs">
          {strings.modalRegister.haveAccount}{' '}
          <a className="text-nIndigo-600 hover:underline" href={parameters.loginPageUrl}>
            {strings.modalRegister.login}
          </a>
        </div>
      </div>
    </div>
  );
}
