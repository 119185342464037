import React from 'react';

import { ModalKeys } from '../../context/modals';
import { strings } from '../../localization/strings';
import TextPrivacyPolicy from '../text/privacy-policy';
import ModalText from './text-modal';

export default function ModalPrivacyPolicy() {
  return (
    <ModalText type={ModalKeys.Privacy} title={strings.textPage.privacyPolicy.title}>
      <TextPrivacyPolicy />
    </ModalText>
  );
}
