import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { updateCurrentUser } from '../../services/api/users';
import CommonAnimatedLoader from '../common/animated/loader';
import AlertPrimary from '../common/alerts/alert-primary';
import StyledLabel from '../common/form/styled-label';
import { strings } from '../../localization/strings';
import StyledInput from '../common/form/styled-input';
import StyledError from '../common/form/styled-error';
import CommonButtonDefault from '../common/buttons/default';
import FileUploader from './file-uploader';
import { useAuth } from '../../hooks/use-auth';
import images from '../../assets/images';

type Inputs = {
  common: string;
  firstName: string;
  lastName: string;
};

export default function ProfileUpdateFormComponent() {
  const { user, token, isAllowed } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
  });
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);
      await updateCurrentUser(token, { firstName: data.firstName, lastName: data.lastName });

      setIsLoading(false);
    } catch (e) {
      if (e instanceof Error) {
        setIsLoading(false);
        setError('common', { message: e.message });
      }
    }
  };

  const onFileUpload = (isSuccess: boolean, imageUrl: string) => {
    if (user) {
      // console.log(imageUrl);
      user.imageUrl = imageUrl;
    }

    setSuccess(true);
  };

  return (
    <>
      {isLoading && <CommonAnimatedLoader />}
      {errors.common && <AlertPrimary type="danger" text={errors.common.message} />}
      <div className="gap-x-8 gap-y-10 pb-10">
        <div className="mb-6">
          <h2 className="text-base font-semibold leading-7">{strings.profile.settingsPage.mainInfoTitle}</h2>
          {/* <p className="mt-1 text-sm leading-6 text-gray-400">Use a permanent address where you can receive mail.</p> */}
        </div>

        {success && (
          <div className="mb-4">
            <AlertPrimary type="success" title={strings.profile.settingsPage.updateMainInfo.alertSuccess} />{' '}
          </div>
        )}

        <div className="col-span-full flex items-center gap-x-8 mb-7">
          {user?.imageUrl ? (
            <img
              src={user?.imageUrl}
              alt={user.firstName}
              className="h-24 w-24 flex-none rounded-full bg-gray-200 object-cover"
            />
          ) : (
            <img
              src={images.AVATAR}
              alt={user?.firstName}
              className="h-24 w-24 flex-none rounded-full bg-gray-200 object-cover"
            />
          )}
          {isAllowed('post-users-profile-update') && (
            <div>
              <FileUploader token={token} onSuccess={onFileUpload} />
              <p className="text-xs leading-5 text-gray-400">
                {strings.profile.settingsPage.updateMainInfo.fileFormats}
              </p>
            </div>
          )}
        </div>

        <form className="md:col-span-2" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:max-w-xl sm:grid-cols-6">
            <div className="sm:col-span-3">
              <StyledLabel htmlFor="first-name">{strings.form.name}</StyledLabel>
              <StyledInput
                fullWidth
                type="text"
                id="first-name"
                disabled={!isAllowed('post-users-profile-update')}
                autoComplete="given-name"
                className={` ${errors.firstName && `!border-red-500`} `}
                {...register('firstName', { required: true })}
              />
              {errors.firstName && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
            </div>

            <div className="sm:col-span-3">
              <StyledLabel htmlFor="last-name">{strings.form.surname}</StyledLabel>
              <StyledInput
                fullWidth
                type="text"
                disabled={!isAllowed('post-users-profile-update')}
                id="last-name"
                autoComplete="family-name"
                className={` ${errors.firstName && `!border-red-500`} `}
                {...register('lastName', { required: true })}
              />
              {errors.firstName && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
            </div>

            <div className="col-span-full">
              <label htmlFor="email" className="block text-sm font-medium leading-6 ">
                {strings.form.email}
              </label>

              <StyledInput disabled className="!w-full mt-2" id="email" name="email" type="email" value={user?.email} />
            </div>

            {/* <div className="col-span-full"> */}
            {/*  <label htmlFor="username" className="block text-sm font-medium leading-6 "> */}
            {/*    Username */}
            {/*  </label> */}
            {/*  <div className="mt-2"> */}
            {/*    <div className="flex rounded-md bg-white ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"> */}
            {/*      <span className="flex select-none items-center pl-3 text-gray-400 sm:text-sm">example.com/</span> */}
            {/*      <input */}
            {/*        type="text" */}
            {/*        name="username" */}
            {/*        id="username" */}
            {/*        autoComplete="username" */}
            {/*        className="flex-1 border-0 bg-transparent py-1.5 pl-1  focus:ring-0 sm:text-sm sm:leading-6" */}
            {/*        placeholder="janesmith" */}
            {/*      /> */}
            {/*    </div> */}
            {/*  </div> */}
            {/* </div> */}
          </div>

          {isAllowed('post-users-profile-update') && (
            <div className="mt-6 flex">
              <CommonButtonDefault primary xl type="submit">
                {strings.button.save}
              </CommonButtonDefault>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
