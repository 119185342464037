import React from 'react';
import { Outlet } from 'react-router-dom';

import LayoutHeaderComponent from './header';

function LayoutFullWidthComponent() {
  return (
    <div className="min-h-screen bg-nIndigo-100">
      <LayoutHeaderComponent />

      <div className="mx-auto sm:px-6 lg:flex lg:gap-8 lg:px-8 mt-4">
        <Outlet />
      </div>
    </div>
  );
}

export default LayoutFullWidthComponent;
