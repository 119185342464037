import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useComments } from '../../hooks/use-comments';
import CommonButtonDefault from '../common/buttons/default';
import * as commentsApi from '../../services/api/comments';
import AlertPrimary from '../common/alerts/alert-primary';
import { strings } from '../../localization/strings';
import { useAuth } from '../../hooks/use-auth';
import { useAnonymousComments } from '../../hooks/use-anonymous-comments';

interface ComponentProps {
  level: number;
  isList: boolean;
}

function CommentsLoaderComponent({ level, isList }: ComponentProps) {
  const { token } = useAuth();
  const { showAnonymousComments } = useAnonymousComments();
  const { comments, article, addComments } = useComments();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  let totalComments = 0;

  if (article) {
    totalComments = article && (showAnonymousComments ? article?.totalComments : article?.totalCommentsLogged);
  }

  const showLoadMore = comments && level === 0 && comments.length < totalComments;

  const loadMoreComments = async () => {
    setIsLoading(true);
    if (article) {
      try {
        const moreComments = await commentsApi.getComments(token, article.id, '', comments[comments.length - 1].id);

        addComments(moreComments);
      } catch (e) {
        setError((e as Error).message);
      }
      setIsLoading(false);
    }
  };

  const openArticleView = () => {
    navigate(`/articles/view/${article?.slug}/${article?.id}`);
  };

  if (isList && comments && comments.length > 1) {
    return (
      <CommonButtonDefault link onClick={openArticleView}>
        {strings.button.showAllComments}
      </CommonButtonDefault>
    );
  }

  if (isLoading) {
    return <div>{strings.loading.waitComments}</div>;
  }

  if (showLoadMore) {
    return (
      <>
        {error && (
          <div className="my-3">
            <AlertPrimary text={error} type="danger" />
          </div>
        )}
        <div className="flex justify-center mt-4">
          <CommonButtonDefault linShowMore onClick={loadMoreComments}>
            {strings.button.showMoreComments}
          </CommonButtonDefault>
        </div>
      </>
    );
  }

  return null;
}

export default CommentsLoaderComponent;
