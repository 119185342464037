import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createTheme } from 'styled-breakpoints';
import { ThemeProvider } from 'styled-components';

import { AuthContextProvider } from '../context/auth';
import Root from './root/root';
import { ModalsContextProvider } from '../context/modals';
import { LayoutContextProvider } from '../context/layout';
import { AnonymousContextProvider } from '../context/anonymous-comments';

const queryClient = new QueryClient();

const breakpointsTheme = createTheme({
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  xxl: '1536px',
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <AnonymousContextProvider>
          <ModalsContextProvider>
            <LayoutContextProvider>
              <BrowserRouter>
                <ThemeProvider theme={breakpointsTheme}>
                  <Root />
                </ThemeProvider>
              </BrowserRouter>
            </LayoutContextProvider>
          </ModalsContextProvider>
        </AnonymousContextProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  );
}
