import React from 'react';

import UsersRegisterFormComponent from '../../components/users/register-form';
import RoundedBlock from '../../components/common/blocks/roundedBlock';
import Heading2 from '../../components/common/headings/heading2';
import { strings } from '../../localization/strings';
import UsersSocialButtonsComponent from '../../components/users/social-buttons';

function UsersRegisterPageComponent() {
  return (
    <RoundedBlock>
      <Heading2 className="mb-6">{strings.registration.title}</Heading2>
      <UsersSocialButtonsComponent />
      <div className="relative my-8">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-4 text-sm font-medium text-gray-500">{strings.form.or}</span>
        </div>
      </div>
      <UsersRegisterFormComponent />
      <div className="mt-4 text-center">
        {strings.registration.haveAccount}{' '}
        <a className="text-nIndigo-600" href="/users/login">
          {strings.registration.login}
        </a>
      </div>
    </RoundedBlock>
  );
}

export default UsersRegisterPageComponent;
