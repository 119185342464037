import React from 'react';

import { ModalKeys } from '../../context/modals';
import { strings } from '../../localization/strings';
import ModalText from './text-modal';
import DeleteUserForm from '../profile/delete-user-form';

export default function ModalDeleteUser() {
  return (
    <ModalText sm type={ModalKeys.DeleteUser} title={strings.profile.settingsPage.deleteAccount.title}>
      <DeleteUserForm />
    </ModalText>
  );
}
