import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { Popover } from '@headlessui/react';

import { Article } from '../../entities/article';
import { getDateWithTime } from '../../services/formatter/date';
import { useComments } from '../../hooks/use-comments';
import IMAGES from '../../assets/images';
import { strings } from '../../localization/strings';
import CommentsListComponent from '../comments/comments-list';
import { vote } from '../../services/api/articles';
import { useAuth } from '../../hooks/use-auth';
import AlertPrimary from '../common/alerts/alert-primary';
import { ModalKeys } from '../../context/modals';
import { useModal } from '../../hooks/use-modal';
import { parameters } from '../../constants/parameters';
import { useAnonymousComments } from '../../hooks/use-anonymous-comments';

interface ComponentProps {
  article: Article;
  isListItem?: boolean;
  onUpdate?: (id: string, ratingDown: number, ratingUp: number, ratingByUsers?: number) => void;
}

const ArticleButton = styled.button<{ primary?: boolean; secondary?: boolean }>`
  ${tw`inline-flex items-center space-x-1 hover:text-gray-500 text-md disabled:opacity-50 disabled:hover:text-gray-900`}
  ${(props) => props.primary && tw`text-nOrange-300 hover:text-orange-500`}
  ${(props) => props.secondary && tw`text-nIndigo-600 hover:text-indigo-300`}
`;

const ButtonIcon = styled.span`
  ${tw`text-md sm:text-[16px] inline-flex items-center`}
`;

const ImgContainer = styled.div`
  ${tw`h-48 sm:h-56 sm:h-[360px] flex relative z-0 mt-3 bg-center bg-no-repeat bg-cover`}
`;

const StyledPopoverPanel = styled.div`
  ${tw`bg-white rounded w-48 border border-b-gray-50 py-1.5 px-2 text-xs drop-shadow`}
`;

const Title = styled.h2`
  ${tw`mt-3 text-base font-medium text-gray-900`}
`;

export default function ArticleListItemComponent({ article, isListItem, onUpdate }: ComponentProps) {
  const { setIsCommenting } = useComments();
  const { toggleAnonymousComments, showAnonymousComments } = useAnonymousComments();
  const { token } = useAuth();
  const [errorArticleVote, setErrorArticleVote] = useState<string>('');
  const { setVisible, setParams } = useModal();
  const { user } = useAuth();
  const onStartCommenting = () => {
    setIsCommenting(true);
  };

  const voteArticle = async (articleId: string, type: string) => {
    try {
      const res = await vote(articleId, type, token);

      if (onUpdate && user) {
        const userVote = res.ratingByUsers.find((item) => item.userId === user.id);

        onUpdate(articleId, res.ratingDown, res.ratingUp, userVote?.vote);
      }
    } catch (e) {
      setErrorArticleVote((e as Error).message);
      setTimeout(() => {
        // After 3 seconds set the show value to false
        setErrorArticleVote('');
      }, 3000);
    }
  };

  const onOpenModal = () => {
    setParams({ url: `${parameters.api.websiteUrl}/articles/view/${article.slug}/${article.id}` });
    setVisible(ModalKeys.ShareArticle, true);
  };

  // console.log(showAnonymousComments, 'showAnonymousComments');
  const onShowAnonymousComments = () => {
    toggleAnonymousComments();
  };

  return (
    <article
      className="bg-white px-4 py-6 shadow sm:rounded-lg sm:p-6"
      aria-labelledby={`question-title-${article.id}`}
    >
      <div>
        <div className="flex space-x-3">
          <div className="flex-shrink-0">
            <img className="h-10 w-10 rounded-full" src={article.authorImageUrl} alt="" />
          </div>
          <div className="min-w-0 flex-1">
            <p className="text-sm text-gray-900">
              <span className="font-medium">{article.authorName}</span>
            </p>
            <p className="text-xs text-nIndigo-300">
              <time>{getDateWithTime(article.publishedAt)}</time>
            </p>
          </div>
        </div>
        {isListItem ? (
          <Title className="hover:text-nIndigo-600" id={`question-title-${article.externalId}`}>
            <a className="d-block" href={`/articles/view/${article.slug}/${article.id}`}>
              {article.title}
            </a>
          </Title>
        ) : (
          <Title id={`question-title-${article.externalId}`}>{article.title}</Title>
        )}
      </div>
      {/* eslint-disable-next-line react/no-danger */}
      <div className="mt-1 space-y-4 text-sm text-gray-700" dangerouslySetInnerHTML={{ __html: article.excerpt }} />
      {isListItem ? (
        <div className="relative z-0">
          <a className="d-block" href={`/articles/view/${article.slug}/${article.id}`}>
            <ImgContainer
              style={{
                backgroundImage: `url(${article.imageUrl})`,
              }}
            />
          </a>
          <a
            className="bg-white z-20 rounded-full px-5 py-3 absolute right-3 bottom-3 font-semibold inline-flex align-middle justify-center text-center items-center flex-nowrap w-auto space-x-3 hover:bg-nIndigo-100"
            href={article.link}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className="w-auto h-[20px]" src={IMAGES.LOGOS.DELFI} alt="" />
            <div className="whitespace-nowrap">{strings.button.readMore}</div>
          </a>
        </div>
      ) : (
        <ImgContainer
          style={{
            backgroundImage: `url(${article.imageUrl})`,
          }}
        >
          <a
            className="z-10 bg-white rounded-full px-5 py-3 absolute right-3 bottom-3 font-semibold inline-flex align-middle justify-center text-center items-center flex-nowrap w-auto space-x-3 hover:bg-nIndigo-100"
            href={article.link}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className="w-auto h-[20px]" src={IMAGES.LOGOS.DELFI} alt="" />{' '}
            <div className="whitespace-nowrap">{strings.button.readMore}</div>
          </a>
        </ImgContainer>
      )}

      <div className="my-3 flex flex-wrap sm:space-x-5 items-center pb-2 border-b border-nIndigo-100">
        <div className="flex w-full sm:w-auto space-x-3 sm:space-x-4 items-center text-sm">
          <div className="inline-flex items-center space-x-3 relative">
            {errorArticleVote && (
              <div className="absolute top-[-27px] left-0 min-w-[180px]">
                <AlertPrimary size="xs" type="danger" title={errorArticleVote} />
              </div>
            )}
            {user ? (
              <>
                <ArticleButton
                  primary={article.userVote === 1}
                  type="button"
                  onClick={() => voteArticle(article.id, 'like')}
                  disabled={!user}
                >
                  <ButtonIcon className="icon-like" aria-hidden="true" />
                  <span className="font-medium">{article.ratingUp}</span>
                  <span className="sr-only">{strings.button.like}</span>
                </ArticleButton>
                <ArticleButton
                  primary={article.userVote === -1}
                  type="button"
                  disabled={!user}
                  onClick={() => voteArticle(article.id, 'dislike')}
                >
                  <ButtonIcon className="icon-dislike" aria-hidden="true" />
                  <span className="font-medium">{article.ratingDown}</span>
                  <span className="sr-only">{strings.button.dislike}</span>
                </ArticleButton>
              </>
            ) : (
              <>
                <Popover className="relative inline-flex items-center">
                  <Popover.Button className="inline-flex items-center space-x-1 hover:text-gray-500 text-md focus:ring-0 focus-visible:ring-0`">
                    <ButtonIcon className="icon-like" aria-hidden="true" />
                    <span className="font-medium">{article.ratingUp}</span>
                    <span className="sr-only">{strings.button.like}</span>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10">
                    {({ close }) => (
                      <StyledPopoverPanel>
                        <Popover.Button
                          onClick={async () => {
                            close();
                          }}
                        >
                          {strings.article.alertOnlyForUsers}
                        </Popover.Button>
                      </StyledPopoverPanel>
                    )}
                  </Popover.Panel>
                </Popover>
                <Popover className="relative inline-flex items-center">
                  <Popover.Button className="inline-flex items-center space-x-1 hover:text-gray-500 text-md focus:ring-0 focus-visible:ring-0`">
                    <ButtonIcon className="icon-dislike" aria-hidden="true" />
                    <span className="font-medium">{article.ratingDown}</span>
                    <span className="sr-only">{strings.button.dislike}</span>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10">
                    {({ close }) => (
                      <StyledPopoverPanel>
                        <Popover.Button
                          onClick={async () => {
                            close();
                          }}
                        >
                          {strings.article.alertOnlyForUsers}
                        </Popover.Button>
                      </StyledPopoverPanel>
                    )}
                  </Popover.Panel>
                </Popover>
              </>
            )}
          </div>
        </div>
        <div className="space-x-3 flex mt-1 sm:mt-0 flex-grow">
          <ArticleButton primary type="button" onClick={onStartCommenting}>
            <ButtonIcon className="icon-comment" aria-hidden="true" />
            <span className="font-medium">{strings.article.comment}</span>
          </ArticleButton>
          <ArticleButton type="button" onClick={() => onOpenModal()}>
            <ButtonIcon className="icon-share" aria-hidden="true" />
            <span className="font-medium">{strings.button.share}</span>
          </ArticleButton>
          <ArticleButton type="button" className="text-xs space-x-1 text-gray-400 hover:text-gray-500 !ml-auto">
            <span className="icon-comment text-md" aria-hidden="true" />
            <span className="font-medium">
              {!showAnonymousComments && article.totalComments > article.totalCommentsLogged ? (
                <>
                  {article.totalCommentsLogged} (+{article.totalComments - (article.totalCommentsLogged || 0)})
                </>
              ) : (
                article.totalComments
              )}
            </span>
            <span className="sr-only">{strings.article.commentCount}</span>
          </ArticleButton>
        </div>
      </div>

      <CommentsListComponent level={0} isInList={isListItem} />
      <div className="flex items-center justify-end">
        <div className="text-right">
          <label htmlFor="comment-show-all" className="select-none text-xs leading-tight text-nIndigo-600 relative">
            {strings.comments.showAll}
          </label>
        </div>
        <div className="ml-2 h-6 relative">
          <input
            id="comment-show-all"
            name="comment-show-all"
            type="checkbox"
            checked={showAnonymousComments}
            onChange={onShowAnonymousComments}
            className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
        </div>
      </div>
    </article>
  );
}

ArticleListItemComponent.defaultProps = {
  isListItem: false,
  onUpdate: () => null,
};
