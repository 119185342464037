import { useContext } from 'react';

import { CommentsContext } from '../context/comments';

export const useComments = () => {
  const context = useContext(CommentsContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useComments was used outside of its Provider');
  }

  return context;
};
