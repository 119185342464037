import React from 'react';

import UsersLoginForm from '../../components/users/login-form';
import Heading2 from '../../components/common/headings/heading2';
import { strings } from '../../localization/strings';
import StyledLink from '../../components/common/text/link';
import RoundedBlock from '../../components/common/blocks/roundedBlock';
import UsersSocialButtonsComponent from '../../components/users/social-buttons';

function UsersLoginPageComponent() {
  return (
    <RoundedBlock>
      <Heading2 className="mb-16">{strings.login.title}</Heading2>
      <p className="mb-7">
        {strings.login.agreementExplanation}{' '}
        <StyledLink href="/terms-conditions">{strings.textPage.termsAndConditions.titleKuo}</StyledLink> {strings.and}{' '}
        <StyledLink href="/privacy-policy">{strings.textPage.privacyPolicy.titleKuo}</StyledLink>.
      </p>
      <UsersSocialButtonsComponent />
      <div className="relative my-8">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-4 text-sm font-medium text-gray-500">{strings.form.or}</span>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="mx-auto w-full max-w-sm lg:w-80">
          <UsersLoginForm />
          <div className="mt-4 text-center">
            {strings.login.newMember}{' '}
            <a className="text-nIndigo-600 hover:underline" href="/users/register">
              {strings.login.register}
            </a>
          </div>
        </div>
      </div>
    </RoundedBlock>
  );
}

export default UsersLoginPageComponent;
