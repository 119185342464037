const getValueIndex = (value: number, locale: string): number => {
  switch (locale) {
    case 'en':
      return value === 1 ? 0 : 1;
    case 'lt':
    default:
      if (value % 10 === 1 && value % 100 !== 11) {
        return 0;
      }

      return value % 10 >= 2 && (value % 100 < 10 || value % 100 >= 20) ? 1 : 2;
  }
};

export const pluralize = (value: number, translation: string): string => {
  const messageParts = translation.split(/\|/);
  const valueIndex = getValueIndex(value, 'lt');

  return messageParts[valueIndex] ?? translation;
};

export const pluralizeWithNumber = (value: number, translation: string): string => {
  return `${value} ${pluralize(value, translation)}`;
};
