import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Editor from 'ckeditor5-custom-build/build/ckeditor';

interface ComponentProps {
  value: string;
  minimal?: boolean;
  onChange: (text: string) => void;
}

function CKEditorInput({ value, onChange, minimal }: ComponentProps) {
  const minimalToolbar = ['bold', 'italic', 'emoji'];

  const fullToolbar = [
    'bold',
    'italic',
    'link',
    'emoji',
    '|',
    'indent',
    'outdent',
    '|',
    'fontsize',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'numberedList',
    'bulletedList',
    'blockQuote',
    'mediaEmbed',
  ];

  return (
    <CKEditor
      editor={Editor}
      data={value || ''}
      config={{
        toolbar: minimal ? minimalToolbar : fullToolbar,
      }}
      onChange={(event: any, editor: any) => {
        const data = editor.getData();

        onChange(data);
      }}
    />
  );
}

CKEditorInput.defaultProps = {
  minimal: false,
};

export default CKEditorInput;
