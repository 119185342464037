import React from 'react';
import { useNavigate } from 'react-router-dom';

import { parameters } from '../../../constants/parameters';
import IMAGES from '../../../assets/images';
import HeadingHero from '../headings/headingHero';
import { strings } from '../../../localization/strings';
import CommonButtonDefault from '../buttons/default';
import { useModal } from '../../../hooks/use-modal';

export default function RegisterAnonymous() {
  const navigate = useNavigate();
  const { setVisible } = useModal();

  const onRegisterClick = () => {
    setVisible('Register', false);
    navigate(parameters.registerPageUrl);
  };

  return (
    <div>
      <div className="flex justify-center mt-4 mb-7">
        <img src={IMAGES.MODAL.REGISTERANONYMOUS} alt="" aria-hidden="true" />
      </div>
      <div>
        <HeadingHero>{strings.modalAnonymousCommenter.title}</HeadingHero>
        <div className="md:w-96 text-md mx-auto text-center">{strings.modalAnonymousCommenter.description}</div>
        <CommonButtonDefault
          wide
          xl
          primary
          className="mt-5 md:mt-7 mx-auto"
          type="submit"
          onClick={() => onRegisterClick()}
        >
          {strings.modalRegister.button}
        </CommonButtonDefault>
        <div className="mt-4 text-center text-xs">
          {strings.modalRegister.haveAccount}{' '}
          <a className="text-nIndigo-600 hover:underline" href={parameters.loginPageUrl}>
            {strings.modalRegister.login}
          </a>
        </div>
      </div>
    </div>
  );
}
