import React, { useState } from 'react';

import CommonAnimatedSpinner from '../common/animated/spinner';
import AlertPrimary from '../common/alerts/alert-primary';
import { strings } from '../../localization/strings';
import CommonButtonDefault from '../common/buttons/default';

interface ComponentProps {
  onClick: () => Promise<boolean>;
  children: React.ReactNode;
  successMessage?: string;
  highlight?: boolean;
}

function CommentsFeedbackButtonComponent({ onClick, children, highlight, successMessage }: ComponentProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const triggerAction = async () => {
    try {
      setIsLoading(true);

      const res = await onClick();

      setIsLoading(false);

      if (res) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      }
    } catch (e) {
      setIsLoading(false);
      setError(strings.error.request.common);
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  };

  return isLoading ? (
    <CommonAnimatedSpinner sm />
  ) : (
    <div className="relative">
      {error && (
        <div className="absolute top-[-27px] left-0 min-w-[180px]">
          <AlertPrimary size="xs" type="danger" title={error} />
        </div>
      )}
      {success && (
        <div className="absolute top-[-27px] left-0 min-w-[180px]">
          <AlertPrimary size="xs" type="success" title={successMessage} />
          {/*  strings.comments.reportSuccess */}
        </div>
      )}
      <CommonButtonDefault commentLink={!highlight} commentReplyLink={highlight} onClick={triggerAction}>
        {children}
      </CommonButtonDefault>
    </div>
  );
}

CommentsFeedbackButtonComponent.defaultProps = {
  successMessage: '',
  highlight: false,
};

export default CommentsFeedbackButtonComponent;
