import React from 'react';
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/24/outline';

interface ComponentProps {
  title?: string;
  text?: string;
  type?: 'danger' | 'success' | 'failed';
  marginTop?: string;
  size?: 'xs' | 'md';
}

function AlertPrimary({ title, text, type, marginTop, size }: ComponentProps) {
  return (
    <div
      className={classNames('bg-gray-100 mb-2', marginTop, {
        'bg-red-50': type === 'failed' || type === 'danger',
        'bg-green-50': type === 'success',
        'py-3 px-4 md:px-5 rounded-2xl': size === 'md',
        'py-1 px-2 rounded-sm inline-block': size === 'xs',
      })}
    >
      <div className="flex-row flex items-center">
        {size !== 'xs' && (
          <div className="w-8">
            {type === 'success' && (
              <div className="mx-auto flex items-center justify-center h-8 w-8 rounded-full bg-green-400">
                <CheckIcon className="h-6 w-6 text-white" />
              </div>
            )}
            {type === 'failed' && (
              <XCircleIcon className="h-8 w-8 mb-2 mx-auto md:mb-0 md:h-10 md:w-10 text-red-400" aria-hidden="true" />
            )}
            {type !== 'failed' && type !== 'success' && (
              <ExclamationCircleIcon
                className={`h-8 w-8 mb-2 mx-auto md:mb-0 md:h-9 md:w-9 text-gray-700 ${
                  type === 'danger' && 'text-red-500'
                }`}
                aria-hidden="true"
              />
            )}
          </div>
        )}

        <div
          className={classNames({
            'text-red-800': type === 'failed' || type === 'danger',
            'text-green-800': type === 'success',
            'pl-4': size !== 'xs',
          })}
        >
          {title && <h3 className={`font-bold ${size === 'xs' ? 'text-xs' : 'font-base'}`}>{title}</h3>}
          {text && <div className={`mt-1 ${size === 'xs' ? 'text-xs' : 'text-sm'}`}>{text}</div>}
        </div>
      </div>
    </div>
  );
}
AlertPrimary.defaultProps = {
  type: 'primary',
  size: 'md',
  title: '',
  text: '',
  marginTop: 'mt-0',
};

export default AlertPrimary;
