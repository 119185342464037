import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';

import { completePasswordReset, validatePasswordReset } from '../../services/api/users';
import { strings } from '../../localization/strings';
import CommonAnimatedLoader from '../common/animated/loader';
import StyledLabel from '../common/form/styled-label';
import StyledInput from '../common/form/styled-input';
import StyledError from '../common/form/styled-error';
import CommonButtonDefault from '../common/buttons/default';
import AlertPrimary from '../common/alerts/alert-primary';
import { parameters } from '../../constants/parameters';
import Heading2 from '../common/headings/heading2';

type Inputs = {
  common: string;
  email: string;
  password: string;
  repeatPassword: string;
};

function UsersResetPasswordForm() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const { token } = useParams();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);

      if (token) {
        await completePasswordReset(token, data.password);
        setIsSuccess(true);
      }

      setIsLoading(false);
    } catch (e) {
      if (e instanceof Error) {
        // console.log(e.response);

        setIsLoading(false);
        setError('common', { message: e.message });
      }
    }
  };

  useEffect(() => {
    const validateToken = async () => {
      try {
        if (token) {
          const userFromToken = await validatePasswordReset(token);

          setValue('email', userFromToken.email);
          setIsValid(true);
          setIsLoading(false);
        }
      } catch (e) {
        if (e instanceof Error) {
          setIsLoading(false);
          setError('common', { message: e.message });
        }
      }
    };

    validateToken();
  }, [token, setValue, setError]);

  return (
    <>
      {isLoading && <CommonAnimatedLoader />}
      {errors.common && <AlertPrimary type="danger" text={errors.common.message} />}

      {isSuccess && !!errors && (
        <div className="mb-6 md:mb-10">
          <AlertPrimary type="success" text={strings.forgotPassword.changePasswordSuccessTitle} />
          <Link
            className="mt-4 inline-block px-5 !no-underline border border-nIndigo-600 text-nIndigo-600 bg-white hover:bg-nIndigo-600 hover:text-white text-md rounded-full leading-none py-3 font-bold"
            to="/users/login"
          >
            {strings.forgotPassword.linkToLogin}
          </Link>
        </div>
      )}

      {!isSuccess && isValid && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-2">
            <StyledLabel htmlFor="email">{strings.form.email}</StyledLabel>
            <StyledInput
              fullWidth
              disabled
              className={` ${errors.email && `!border-red-500`} `}
              type="email"
              {...register('email')}
            />
            {errors.email && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
          </div>
          <div className="py-2">
            <StyledLabel htmlFor="password">{strings.form.newPassword}</StyledLabel>
            <StyledInput
              fullWidth
              className={` ${errors.password && `!border-red-500`} `}
              type="password"
              {...register('password', {
                required: true,
                minLength: parameters.password.minLength,
              })}
            />
            {errors.password && (
              <StyledError>
                {errors.password.type === 'required' && strings.error.fieldIsRequired}
                {errors.password.type === 'minLength' &&
                  strings.error.password.minLength.replace('{length}', `${parameters.password.minLength}`)}
              </StyledError>
            )}
          </div>
          <div className="py-2">
            <StyledLabel htmlFor="repeatPassword">{strings.form.repeatPassword}</StyledLabel>
            <StyledInput
              fullWidth
              className={` ${errors.repeatPassword && `!border-red-500`} `}
              type="password"
              {...register('repeatPassword', {
                required: true,
                validate: { same: (value) => value === getValues('password') },
              })}
            />
            {errors.repeatPassword && (
              <StyledError>
                {errors.repeatPassword.type === 'required' && strings.error.fieldIsRequired}
                {errors.repeatPassword.type === 'same' && strings.error.password.same}
              </StyledError>
            )}
          </div>

          <CommonButtonDefault
            wide
            xl
            primary
            className="mt-5 md:mt-7 mx-auto"
            type="submit"
            onClick={() => {
              clearErrors('common');
            }}
          >
            {strings.button.changePassword}
          </CommonButtonDefault>
        </form>
      )}
    </>
  );
}

export default UsersResetPasswordForm;
