import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { getExternalArticle } from '../../services/api/articles';
import { Article } from '../../entities/article';
import { strings } from '../../localization/strings';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import { CommentsContextProvider } from '../../context/comments';
import CommentsListComponent from '../../components/comments/comments-list';
import CommonButtonDefault from '../../components/common/buttons/default';
import { useAuth } from '../../hooks/use-auth';
import { requestIframeResize } from '../../services/post-message';
import useWindowDimensions from '../../hooks/use-window-dimensions';

export default function CommentsShortComponent() {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [article, setArticle] = useState<Article>();
  const height = useWindowDimensions();
  const { token } = useAuth();
  const { portal, articleId } = useParams();
  const [queryParams] = useSearchParams();

  const onRedirect = () => {
    if (window.parent) {
      window.parent.location.href = queryParams.get('redirectUrl') || '';
    } else {
      document.location.href = queryParams.get('redirectUrl') || '';
    }
  };

  useEffect(() => {
    try {
      const fetchArticle = async () => {
        if (articleId && portal) {
          setIsLoading(true);

          const item = await getExternalArticle(token, portal, articleId);

          setIsLoading(false);

          setArticle(item);
        }
      };

      fetchArticle();
    } catch (e) {
      setIsLoading(false);
      setError((e as Error).message);
    }
  }, [articleId, portal, token]);

  useEffect(() => {
    requestIframeResize(queryParams.get('targetElementId'));
  }, [height, queryParams]);

  return (
    <div>
      {error && <div>{error}</div>}
      {isLoading && (
        <div className="py-5 flex align-middle justify-center">
          <CommonAnimatedSpinner />
        </div>
      )}
      {article && (
        <div className="border-[3px] border-[#E2EAEE] rounded-[10px] bg-white py-4 px-5 leading-tight text-xs">
          <CommentsContextProvider comments={article?.comments} article={article}>
            <CommentsListComponent level={0} isShortComponent />
          </CommentsContextProvider>

          <CommonButtonDefault default className="w-full !rounded-md" onClick={onRedirect}>
            {strings.article.writeComment}
          </CommonButtonDefault>
        </div>
      )}
    </div>
  );
}
