import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import { parameters } from '../../constants/parameters';

function UsersCompleteLoginPageComponent() {
  const { setUserToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const expiredAt = params.get('expiredAt');

    if (token && expiredAt) {
      setUserToken({ token, expiredAt: new Date(expiredAt) }, false);

      navigate(parameters.firstPageUrl);
    }
  }, [navigate, setUserToken]);

  return <div />;
}

export default UsersCompleteLoginPageComponent;
