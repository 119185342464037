import React from 'react';

import Heading2 from '../../components/common/headings/heading2';
import { strings } from '../../localization/strings';
import UsersForgotPasswordForm from '../../components/users/forgot-password-form';
import RoundedBlock from '../../components/common/blocks/roundedBlock';
import StyledLink from '../../components/common/text/link';

function UsersForgotPasswordPageComponent() {
  return (
    <RoundedBlock>
      <Heading2 className="mb-6">{strings.forgotPassword.requestPasswordReset}</Heading2>
      <p className="mb-4">{strings.forgotPassword.text}</p>
      <UsersForgotPasswordForm />
      <p className="mt-4 text-center text-sm">
        {strings.forgotPassword.helpText} <StyledLink href="#">{strings.forgotPassword.help}</StyledLink>
      </p>
    </RoundedBlock>
  );
}

export default UsersForgotPasswordPageComponent;
