import React, { useState } from 'react';

import { strings } from '../../localization/strings';
import { getSocialLoginUrl } from '../../services/api/users';
import { useAuth } from '../../hooks/use-auth';
import * as usersApi from '../../services/api/users';
import CommonAnimatedSpinner from '../common/animated/spinner';
import AlertPrimary from '../common/alerts/alert-primary';
import CommonSwitch from '../common/form/switch';

export default function AssignUserComponent() {
  const { token, isAllowed, user, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState<string>('');
  const [errorKey, setErrorKey] = useState<string>('');
  const [error, setError] = useState<string>('');

  const onFacebookLoginClick = async () => {
    window.location.href = await getSocialLoginUrl(token, 'facebook');
  };

  const onGoogleLoginClick = async () => {
    window.location.href = await getSocialLoginUrl(token, 'google');
  };

  const onDisconnectSocial = async (provider: string, removalField: string) => {
    try {
      setIsLoading(provider);
      await usersApi.updateCurrentUser(token, { [removalField]: 1 });

      if (user) {
        setUser({ ...user, [`${provider}Id`]: null });
      }

      setIsLoading('');
    } catch (e) {
      setIsLoading('');

      setErrorKey(provider);
      setError((e as Error).message);
    }
  };

  return (
    <div className="py-7">
      <div className="mb-6">
        <h2 className="text-base font-semibold leading-7 ">{strings.profile.settingsPage.assignProfile.title}</h2>
        <div className="grid grid-cols-1 md:grid-cols-12">
          <div className="col-span-12">
            <p className="text-sm text-gray-400">{strings.profile.settingsPage.assignProfile.text}</p>
          </div>
          <div className="col-span-7 mt-4 space-y-4">
            {user && (
              <>
                {user.facebookId ? (
                  <div>
                    {errorKey === 'facebook' && <AlertPrimary size="xs" type="danger" title={error} />}
                    {isLoading === 'facebook' ? (
                      <span>
                        <CommonAnimatedSpinner />
                      </span>
                    ) : (
                      <CommonSwitch
                        onClick={() => onDisconnectSocial('facebook', 'removeFacebookId')}
                        isEnabled
                        text={strings.profile.settingsPage.assignProfile.disconnectFromFacebookProfile}
                      />
                    )}
                  </div>
                ) : (
                  <div>
                    <CommonSwitch
                      onClick={onFacebookLoginClick}
                      isEnabled={!!user.facebookId}
                      text={strings.profile.settingsPage.assignProfile.facebook}
                    />
                  </div>
                )}
                {user.googleId ? (
                  <div>
                    {errorKey === 'google' && <AlertPrimary size="xs" type="danger" title={error} />}

                    {isLoading === 'google' ? (
                      <span>
                        <CommonAnimatedSpinner />
                      </span>
                    ) : (
                      <CommonSwitch
                        onClick={() => onDisconnectSocial('google', 'removeGoogleId')}
                        isEnabled={!!user.googleId}
                        text={strings.profile.settingsPage.assignProfile.disconnectFromGoogleProfile}
                      />
                    )}
                  </div>
                ) : (
                  <CommonSwitch
                    onClick={onGoogleLoginClick}
                    isEnabled={!!user.googleId}
                    text={strings.profile.settingsPage.assignProfile.gPlus}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
