import React, { useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone, { IDropzoneProps, ILayoutProps } from 'react-dropzone-uploader';

import { uploadFile } from '../../services/api/users';
import AlertPrimary from '../common/alerts/alert-primary';
import { strings } from '../../localization/strings';

function NoDropzoneLayout({ previews, submitButton, input, files, dropzoneProps, extra: { maxFiles } }: ILayoutProps) {
  const { ref, className, style } = dropzoneProps;

  return (
    <div ref={ref} className={className} style={style}>
      {previews}

      {files.length < maxFiles && input}

      {files.length > 0 && submitButton}
    </div>
  );
}
interface ComponentProps {
  onSuccess: (isSuccess: boolean, imageUrl: string) => void;
  token: string;
}

function FileUploader({ onSuccess, token }: ComponentProps) {
  const [error, setError] = useState();
  const fileUpload = async (file: File) => {
    try {
      const res = await uploadFile(token);

      if (res) {
        const uploadInfo = await fetch(res.uploadUrl, {
          method: 'PUT',
          mode: 'cors',
          body: file,
        });

        // console.log(uploadInfo);

        onSuccess(true, uploadInfo.url);
      }
    } catch (e: any) {
      setError(e.message);
    }
  };
  const handleSubmit: IDropzoneProps['onSubmit'] = async (files, allFiles) => {
    if (files) {
      files.map((f) => {
        return fileUpload(f.file);
      });
    }
    allFiles.forEach((f) => f.remove());
  };

  return (
    <>
      {error && (
        <div className="mb-5 text-left">
          <AlertPrimary type="danger" title={error} text={error} />
        </div>
      )}

      <Dropzone
        onSubmit={handleSubmit}
        maxFiles={1}
        multiple={false}
        canCancel={false}
        inputContent={strings.button.upload}
        submitButtonContent={strings.button.upload}
        LayoutComponent={NoDropzoneLayout}
        accept=".jpg, .jpeg, .png"
        styles={{
          dropzone: {
            minHeight: 46,
            maxHeight: 'auto',
            border: 'none',
          },
          inputLabel: {
            color: '#000',
            fontSize: 14,
            borderRadius: 10,
            background: '#FFF',
            border: '1px solid #D1D5DB',
            display: 'inline-flex',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 1,
            width: 140,
            marginBottom: 15,
          },
          inputLabelWithFiles: {
            color: '#000',
            fontSize: 14,
            borderRadius: 10,
            background: '#FFF',
            border: '1px solid #D1D5DB',
            display: 'inline-flex',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 1,
          },
          preview: {
            paddingTop: 15,
            paddingBottom: 15,
          },
          submitButton: {
            borderRadius: 10,
            fontSize: 14,
            width: 100,
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 15,
            marginTop: 15,
            backgroundColor: '#F58B6D',
          },
          submitButtonContainer: {
            width: '100%',
            margin: 0,
          },
        }}
      />
    </>
  );
}

export default FileUploader;
