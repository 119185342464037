import React from 'react';

import Heading2 from '../../components/common/headings/heading2';
import { strings } from '../../localization/strings';
import UsersResetPasswordForm from '../../components/users/reset-password-form';
import RoundedBlock from '../../components/common/blocks/roundedBlock';

function UsersResetPasswordPageComponent() {
  return (
    <RoundedBlock>
      <Heading2 className="mb-6">{strings.forgotPassword.finishPasswordReset}</Heading2>
      <UsersResetPasswordForm />
    </RoundedBlock>
  );
}

export default UsersResetPasswordPageComponent;
