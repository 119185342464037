import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import Cookies from 'universal-cookie';

import { current } from '../services/api/users';
import { AuthToken } from '../entities/auth-token';
import { Customer } from '../entities/customer';
import { strings } from '../localization/strings';
import { requestDelfiToken } from '../services/post-message';

const COOKIE_NAME_TOKEN_ADMIN = 'admin_token';
const COOKIE_NAME_TOKEN = 'access_token';
const COOKIE_NAME_PUBLIC = 'is_public';
const COOKIE_NAME_USERNAME = 'user_name';
const cookies = new Cookies();

interface ComponentProps {
  children: React.ReactNode;
}

interface ContextValue {
  token: string;
  tokenDelfi: string;
  tokenAdmin: string;
  loading: boolean;
  user?: Customer;
  setUser: (user: Customer) => void;
  setUserToken: (authToken: AuthToken, publicState: boolean) => void;
  signout: () => void;
  isAllowed: (permission: string) => boolean;
  setUserNameCookie: (userName: string) => void;
  userName?: string;
}

const AuthContext = createContext<ContextValue>({
  tokenDelfi: '',
  token: '',
  tokenAdmin: '',
  loading: true,
  setUser: () => null,
  setUserToken: () => null,
  signout: () => null,
  isAllowed: () => false,
  setUserNameCookie: () => null,
  userName: '',
});

const randomUserName = () => {
  return `${strings.anonymousUserPrefix} ${Math.floor(Math.random() * (10000 - 1000 + 1) + 1000)}`;
};

function AuthContextProvider({ children }: ComponentProps) {
  const tokenAdmin = cookies.get(COOKIE_NAME_TOKEN_ADMIN);
  const [token, setToken] = useState<string>(cookies.get(COOKIE_NAME_TOKEN));
  const [tokenDelfi, setTokenDelfi] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<Customer>();
  const [userName, setUserName] = useState<string>(
    window.localStorage.getItem(COOKIE_NAME_USERNAME) || randomUserName(),
  );

  const signout = useCallback(() => {
    setToken('');
    setUser(undefined);
    cookies.remove(COOKIE_NAME_TOKEN, { path: '/' });
    cookies.remove(COOKIE_NAME_PUBLIC, { path: '/' });
  }, []);

  const setUserToken = useCallback(
    (authToken: AuthToken, publicState: boolean) => {
      cookies.set(COOKIE_NAME_TOKEN, authToken.token, {
        expires: authToken.expiredAt,
        path: '/',
      });

      cookies.set(COOKIE_NAME_PUBLIC, publicState ? 1 : 0, {
        expires: authToken.expiredAt,
        path: '/',
      });

      setToken(authToken.token);
      setIsLoading(true);
    },
    [setToken],
  );

  const setUserNameCookie = useCallback(
    (name: string) => {
      window.localStorage.setItem(COOKIE_NAME_USERNAME, name);

      setUserName(name);
    },
    [setUserName],
  );

  useEffect(() => {
    const handleDelfiToken = async (event: MessageEvent) => {
      if (event.origin.indexOf('delfi.lt') === -1) {
        return;
      }

      if (event.data.type === 'loginApiLogout') {
        setTokenDelfi('');
        setUser(undefined);
      } else if (event.data.type === 'loginApiToken') {
        if (event.data.token) {
          setTokenDelfi(event.data.token);
          setUser({
            id: `id-${event.data.id}`,
            role: 'COMMENTER',
            email: event.data.email,
            facebookId: '',
            firstName: event.data.name,
            lastName: '',
            loggedAt: 0,
            loginIps: [],
            permissions: [],
            roles: [],
            imageUrl: event.data.imageUrl,
            phone: '',
            updatedAt: 0,
            createdAt: 0,
          });
        } else {
          setTokenDelfi('');
          setUser(undefined);
        }
      }
    };

    window.addEventListener('message', handleDelfiToken);
    requestDelfiToken();

    return () => {
      window.removeEventListener('message', handleDelfiToken);
    };
  }, []);

  useEffect(() => {
    const fetchUser = async (currToken: string) => {
      try {
        const currUser = await current(currToken);

        setUser(currUser);
        setUserName(userName || randomUserName());
        setIsLoading(false);
      } catch (e) {
        setUser(undefined);
        setIsLoading(false);
      }
    };

    if (!user && token) {
      fetchUser(token);
    }

    if (!token) {
      setIsLoading(false);
    }
  }, [token, setUserToken, user]);

  const isAllowed = useCallback(
    (permission: string) => {
      return (user && user.permissions.includes(permission)) || false;
    },
    [user],
  );

  const contextValue = useMemo(
    () => ({
      token: tokenDelfi ? `DELFI-${tokenDelfi}` : token,
      tokenAdmin,
      tokenDelfi,
      signout,
      user,
      setUser,
      setUserToken,
      isAllowed,
      loading: isLoading,
      setUserNameCookie,
      userName,
    }),
    [
      token,
      tokenAdmin,
      tokenDelfi,
      user,
      setUser,
      signout,
      setUserToken,
      isLoading,
      isAllowed,
      setUserNameCookie,
      userName,
    ],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthContextProvider };
