import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';

import { ModalKeys } from '../../context/modals';
import ModalText from './text-modal';
import { useModal } from '../../hooks/use-modal';
import RegisterAnonymous from '../common/register/register-anonymous';
import { useAuth } from '../../hooks/use-auth';

const COOKIE_NAME = 'hide_anonymous_modal';

export default function ModalAnonymous() {
  const { params } = useModal();

  const { user } = useAuth();

  const { setVisible } = useModal();
  const cookies = new Cookies();

  useEffect(() => {
    const hideModal = cookies.get(COOKIE_NAME);

    if (!user && hideModal !== '1') {
      setVisible(ModalKeys.Anonymous, true);
    }
  }, []);

  return (
    <ModalText md type={ModalKeys.Anonymous} trackCloseCookie={COOKIE_NAME}>
      <RegisterAnonymous />
    </ModalText>
  );
}
