import React, { useState } from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';

interface ComponentProps {
  isEnabled: boolean;
  text: string;
  onClick: () => void;
  subText?: string;
}

export default function CommonSwitch({ isEnabled, text, subText, onClick }: ComponentProps) {
  const [enabled, setEnabled] = useState(isEnabled);

  const onSwitchChange = () => {
    setEnabled(!isEnabled);
    onClick();
  };

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={onSwitchChange}
        className={classNames(
          enabled ? 'bg-nIndigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3 text-sm">
        <span className="font-medium text-gray-900">{text}</span>{' '}
        {subText && <span className="text-gray-500">{subText}</span>}
      </Switch.Label>
    </Switch.Group>
  );
}

CommonSwitch.defaultProps = {
  subText: '',
};
