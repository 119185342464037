import React, { useEffect, useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import classNames from 'classnames';

import { strings } from '../../localization/strings';
import { useAuth } from '../../hooks/use-auth';
import { Category } from '../../entities/category';
import { getCategories } from '../../services/api/articles';
import BannerSidebarComponent from '../banner/sidebar';
import LayoutHeaderComponent from './header';

function LayoutComponent() {
  const { user } = useAuth();
  const [categories, setCategories] = useState<Category[]>();
  const [selectedCategoryId] = useState<string>('6ca312a9-ccac-4a96-8001-eae810972956');

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await getCategories();

      setCategories(res);
    };

    fetchCategories();
  }, []);

  return (
    <div className="min-h-screen bg-nIndigo-100">
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <LayoutHeaderComponent />

      <div className="pb-10 pt-7">
        <div className="mx-auto max-w-3xl sm:px-6 lg:max-w-7xl lg:flex lg:gap-8 lg:px-8">
          {/* sidebar */}
          <div className="hidden lg:block w-[230px] shrink-0">
            <div className="uppercase mb-2 text-[10px] text-nIndigo-300">{strings.sidebar.categories}</div>
            <nav aria-label="Sidebar" className="sticky top-4 divide-y divide-gray-300">
              <div className="space-y-1 pb-8">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    classNames(
                      'text-gray-700 hover:bg-gray-50 w-full flex items-center rounded-md px-3 py-2 text-sm font-medium',
                      {
                        'bg-nIndigo-200 text-gray-900': isActive,
                      },
                    )
                  }
                  aria-current={!selectedCategoryId ? 'page' : undefined}
                >
                  <span className="w-5 mr-5 icon icon-home text-xl" aria-hidden="true" />
                  <span className="truncate">{strings.sidebar.menu.home}</span>
                </NavLink>
                {categories &&
                  categories.map((item) => (
                    <NavLink
                      key={item.id}
                      to={`/articles/${item.slug}/${item.id}`}
                      className={({ isActive }) =>
                        classNames(
                          'text-gray-700 hover:bg-gray-50 w-full flex items-center rounded-md px-3 py-2 text-sm font-medium',
                          {
                            'bg-nIndigo-200 text-gray-900': isActive,
                          },
                        )
                      }
                      aria-current={item.id === selectedCategoryId ? 'page' : undefined}
                    >
                      <span className={`w-5 mr-5 icon icon-${item.key} text-xl`} aria-hidden="true" />
                      <span className="truncate">{item.title}</span>
                    </NavLink>
                  ))}
              </div>
              {!user && (
                <div className="mt-4 pt-4">
                  <p className="text-sm">{strings.sidebar.loginText}</p>
                  <a
                    className="block text-md rounded-full font-bold bg-nOrange-300 text-white hover:bg-orange-400 text-center py-3.5 mt-4"
                    href="/users/register"
                  >
                    {strings.sidebar.joinNapo}
                  </a>
                </div>
              )}
            </nav>
          </div>
          {/* sidebar ends */}

          <main className="w-full">
            <Outlet />
          </main>
          {/* right side */}
          <aside className="hidden lg:w-[300px] xl:block shrink-0">
            <div className="space-y-4">
              <BannerSidebarComponent />
            </div>
          </aside>
          {/* right side ends */}
        </div>
      </div>

      <span
        dangerouslySetInnerHTML={{
          __html: `
          <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="2774a8b0-0513-450d-affc-43490a3f6f1e" data-blockingmode="auto" type="text/javascript"></script>
          <script id="CookieDeclaration" src="https://consent.cookiebot.com/2774a8b0-0513-450d-affc-43490a3f6f1e/cd.js" type="text/javascript" async></script>`,
        }}
      />
    </div>
  );
}

export default LayoutComponent;
