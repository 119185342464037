import React from 'react';
import tw, { styled } from 'twin.macro';

import Heading2 from '../common/headings/heading2';
import Heading3 from '../common/headings/heading3';

const SpacedHeading = styled(Heading2)`
  ${tw`mt-8 mb-1`}
`;

const SpacedSubheading = styled(Heading3)`
  ${tw`mt-4 mb-1`}
`;

export default function TextPrivacyPolicy() {
  return (
    <div>
      <SpacedHeading>1. Ką reiškia ši Privatumo politika?</SpacedHeading>
      <p>
        Šioje privatumo politikoje (toliau – Privatumo politika) Jums pateikiame informaciją apie tai, kaip duomenų
        valdytojo (kliento, kuriam kuriama svetainė) pavadinimas (toliau – Duomenų valdytojas, mes), tvarko Jūsų asmens
        duomenis gautus: pildyti pagal faktą, pavyzdžiui, Jums užsisakant naujienlaiškius, dalyvaujant mūsų
        organizuojamuose projektuose ir pan., Jums lankantis šioje interneto svetainėje ar mūsų socialinės žiniasklaidos
        tinklų paskyrose, susisiekiant su mumis. Toliau visi nurodyti asmenys, kurių duomenis mes tvarkome, vadinami
        Duomenų subjektais.
      </p>

      <SpacedHeading>2. Apie Duomenų valdytoją</SpacedHeading>
      <p>
        Duomenų valdytojas reiškia UAB NUOSTABU, juridinio asmens kodas 304557170, buveinė adresu Laisvės pr. 60-1107,
        LT-05120 Vilnius, duomenys kaupiami ir saugojami Lietuvos Respublikos juridinių asmenų registre. Kontaktinis el.
        paštas asmens duomenų apsaugos klausimais info@nuostabu.lt . arba Duomenų apsaugos pareigūno kontaktinis el.
        pašto adresas vytautas@nuostabu.lt.
      </p>

      <SpacedHeading>3. Kas yra asmens duomenys?</SpacedHeading>
      <p>
        Asmens duomenys yra bet kokia mūsų apie Duomenų subjektą renkama informacija, kuri gali būti panaudota Duomenų
        subjekto asmens tapatybei nustatyti bei yra saugoma elektroniniu ar kitu būdu.
      </p>
      <p>
        Asmens duomenys apima bet kokią informaciją, įskaitant Duomenų subjekto vardą, pavardę, adresą, kurią apie
        Duomenų subjektus mes renkame dėl šioje Privatumo politikoje ar atskirame Duomenų subjekto sutikime ar
        susitarime su mumis nurodytų tikslų.
      </p>

      <SpacedHeading>4. Kokius dar Jūsų asmens duomenis mes galime tvarkyti?</SpacedHeading>
      <p>Duomenų tvarkymo tikslas: Paskyros interneto svetainėje sukūrimas</p>
      <p>Tvarkomi asmens duomenys: Elektroninio pašto adresas, vardas pavardė</p>
      <p>Duomenų tvarkymo pagrindas: BDAR 6 straipsnio 1 dalies b punktas (sutartis)</p>
      <p>Duomenų tvarkymo terminas: Iki paskyros panaikinimo</p>

      <SpacedHeading>5. Susisiekite su mumis</SpacedHeading>
      <p>
        Su mumis galite susisiekti šiais būdais: info@napo.lt Jei susisieksite su mumis mes galime tvarkyti Jūsų mums
        nurodytus duomenis, t. y. vardą pavardę ir el.pašto adresą.
      </p>
      <p>Visus pranešimus priimame, peržiūrime ir atsakymus pateikiame patys.</p>
      <p>
        Tokie duomenys bus tvarkomi siekiant atsakyti į Jums rūpimus klausimus, išnagrinėti Jūsų pasiūlymus. Jei
        nepateiksite savo kontaktinių duomenų, su Jumis nebus įmanoma susisiekti.
      </p>
      <p>
        Susirašinėjimas saugomas 6 mėnesius nuo žinutės gavimo, išskyrus informaciją, kurios saugojimui privatumo
        politikoje ar teisės aktuose nustatyti kiti terminai.
      </p>
      <p>
        Visi asmens duomenys, kuriuos pateikiate bendraudami su mumis, yra naudojami tik aukščiau nurodytais tikslais ir
        pranešimams peržiūrėti bei komunikacijos srautams administruoti ir valdyti. Įsipareigojame be aiškaus Jūsų
        sutikimo nenaudoti Jūsų asmens duomenų jokiuose leidiniuose taip, kad būtų įmanoma nustatyti Jūsų asmens
        tapatybę.
      </p>
      <p>
        Prašome atkreipti dėmesį, kad mums gali tekti susisiekti su Jumis paštu, el. paštu arba telefonu. Prašome
        pranešti mums apie Jūsų asmens duomenų pasikeitimus.
      </p>

      <SpacedHeading>6. Interneto svetainė</SpacedHeading>
      <p>
        Mūsų interneto svetainėje yra naudojami slapukai. Slapukas yra nedidelis iš raidžių ir skaitmenų sudarytas
        failas, kurį Jūsų sutikimu mes įrašome į Jūsų naršyklę arba standųjį Jūsų kompiuterio diską. Siekdami skirtingų
        tikslų, naudojame skirtingus slapukus. Slapukai taip pat padeda mums atskirti Jus nuo kitų Interneto svetainės
        naudotojų, taigi užtikrina malonesnę svetainės naudojimo patirtį ir leidžia tobulinti Interneto svetainę.
      </p>
      <p>
        Dauguma naršyklių leidžia atmesti visus slapukus, o kai kuriose naršyklėse yra galimybė atmesti tik trečiųjų
        šalių slapukus. Tad Jūs galite pasinaudoti šiomis galimybėmis. Tačiau atkreipkite dėmesį į tai, kad visų slapukų
        blokavimas turės neigiamą poveikį Interneto svetainės naudojimui, ir be slapukų Jūs negalėsite naudotis visomis
        Interneto svetainėje teikiamomis paslaugomis. Išsamesnė informacija pateikta adresu AllAboutCookies.org arba
        www.google.com/privacy_ads.html.
      </p>
      <p>
        Mes galime naudoti toliau apibūdintas slapukų rūšis, tačiau detalų ir aktualų naudojamų slapukų sąrašą rasite
        ČIA:
      </p>
      <SpacedSubheading>Absoliučiai būtini slapukai.</SpacedSubheading>
      <p>
        Šie slapukai yra būtini tam, kad mūsų svetainė galėtų veikti. Tokiais slapukais tvarkomų duomenų pagrindas yra
        tinkamas sutarties vykdymas Jums lankantis Interneto svetainėje, o mums užtikrinant apsilankymo kokybę bei
        saugumą. Tai gali būti slapukai, kurie, pavyzdžiui, leidžia Jums prisijungti ir patekti į apsaugotas mūsų
        svetainės zonas, naudotis pirkinių krepšelio funkcija arba el. sąskaitų paslaugomis.
      </p>

      <SpacedSubheading>Analitiniai ir (arba) veiksmingumo slapukai.</SpacedSubheading>
      <p>
        Šie slapukai leidžia mums atpažinti ir suskaičiuoti svetainės lankytojus bei stebėti, kaip lankytojai juda po
        mūsų svetainę ja naudodamiesi. Tai padeda mums pagerinti svetainės veikimą, pavyzdžiui, užtikrinti, kad
        vartotojai galėtų lengvai rasti tai, ko ieško. Šių slapukų renkamų duomenų tvarkymo pagrindas yra Jūsų
        sutikimas.
      </p>

      <SpacedSubheading>Funkciniai slapukai.</SpacedSubheading>
      <p>
        Šie slapukai yra naudojami Jums atpažinti, kai grįžtate į mūsų interneto svetainę, kad galėtume pritaikyti
        pateikiamą turinį Jūsų poreikiams, įsiminti Jums aktualią informaciją. Šių slapukų renkamų duomenų tvarkymo
        pagrindas yra Jūsų sutikimas.
      </p>

      <SpacedSubheading>Komerciniai slapukai.</SpacedSubheading>
      <p>
        Naudojame savo ir trečiųjų šalių slapukus, kad savo bei kitose svetainėse rodytume suasmenintą reklamą. Tai
        vadinama „pakartotine rinkodara“, kuri pagrįsta naršymo veiksmais, pavyzdžiui, jūsų ieškotomis, peržiūrėtomis
        prekėmis.
      </p>

      <SpacedSubheading>Socialinių tinklų (nurodyti konkrečiai, kokių) ikonų įskiepiai</SpacedSubheading>
      <p>
        Šiame puslapyje yra naudojami nurodyti, kokie (Facebook, Instagram ir kt.) ikonų įskiepiai, todėl naršydami
        šioje svetainėje sutinkate, kad jūsų duomenimis galime naudotis pateikti nuorodas į socialinių tinklų paskyras
        paskyrose.
      </p>

      <SpacedHeading>7. Duomenų gavimas ir atskleidimas</SpacedHeading>
      <p>
        Mes gauname Jūsų duomenis iš nurodyti, iš kur gaunami duomenys, pavyzdžiui, iš pačių duomenų subjektų, jų
        atstovų (pavyzdžiui, tėvų) ir pan.
      </p>
      <p>
        Mes galime atskleisti informaciją apie Jus mūsų darbuotojams, tarpininkams, paslaugų tiekėjams jei to pagrįstai
        reikia tiems tikslams, kaip nurodyta šioje Privatumo politikoje.
      </p>
      <p>Be to, mes galime atskleisti informaciją apie Jus:</p>
      <ul>
        <li>jei tai turime padaryti pagal įstatymą;</li>
        <li>
          siekiant apginti savo teises ar interesus (įskaitant Jūsų duomenų pateikimą tretiesiems asmenims, siekiant
          išieškoti Jūsų įsiskolinimus mums);
        </li>
        <li>
          ketinant perleisti Duomenų valdytojo veiklos ar turto dalį, atskleidžiant Jūsų asmens duomenis potencialiam
          veiklos ar jos dalies įgijėjui;
        </li>
        <li>perleidus Duomenų valdytojo veiklą ar jos esminę turto dalį tretiesiems asmenims.</li>
      </ul>
      <p>
        Išskyrus šioje Privatumo politikoje numatytus atvejus, mes neteikiame Jūsų asmens duomenų jokioms trečiosioms
        šalims.
      </p>
      <p>
        Privatumo politikoje nurodytas gavėjų sąrašas ar gavėjų kategorijos gali keistis, todėl jei pageidaujate būti
        informuoti apie Jūsų asmens duomenų gavėjų pokyčius, prašome apie tai mums pranešti šioje Privatumo politikoje
        nurodytu el. paštu, laiško tekste nurodant „Pageidauju gauti informaciją apie mano asmens duomenų gavėjų
        pasikeitimą, vardas, pavardė“.
      </p>

      <SpacedHeading>8. Jūsų asmens duomenų saugumas</SpacedHeading>
      <p>
        Jūsų asmens duomenys bus tvarkomi laikantis Bendrojo duomenų apsaugos reglamento, Lietuvos Respublikos asmens
        duomenų teisinės apsaugos įstatymo ir kitų teisės aktų nustatytų reikalavimų. Tvarkydami Jūsų asmens duomenis,
        mes įgyvendiname organizacines ir technines priemones, kurios užtikrina asmens duomenų apsaugą nuo atsitiktinio
        ar neteisėto sunaikinimo, pakeitimo, atskleidimo, taip pat nuo bet kokio kito neteisėto tvarkymo.
      </p>

      <SpacedHeading>9. Jūsų teisės</SpacedHeading>
      <p>
        Šiame skyriuje pateikiame informaciją apie Jūsų teises, susijusias su mūsų vykdomu Jūsų asmens duomenų tvarkymu,
        ir atvejus, kada šiomis teisėmis galite pasinaudoti. Jei norite gauti daugiau informacijos apie savo teises ar
        jas įgyvendinti, susisiekite su mumis šioje Privatumo politikoje nurodytu el. pašto adresu.
      </p>
      <p>
        Duomenų valdytojas nepagrįstai nedelsdamas, bet ne vėliau, kaip per 1 (vieną) mėnesį nuo prašymo gavimo, Jums
        pateiks informaciją apie veiksmus, kurių imtasi gavus Jūsų prašymą dėl Jūsų teisių įgyvendinimo. Atsižvelgiant į
        prašymo sudėtingumą ir gautų prašymų skaičių, minėtas terminas gali būti pratęstas dar 2 (dviem) mėnesiams.
        Tokiu atveju mes Jus per 1 (vieną) mėnesį nuo prašymo gavimo informuosime apie tokį termino pratęsimą ir jo
        priežastis. Duomenų valdytojas atsisakys įgyvendinti Jūsų teises tik teisės aktų numatytais atvejais.
      </p>

      <SpacedSubheading>15.1. Teisė susipažinti su savo asmens duomenimis</SpacedSubheading>
      <p>
        Siekiame, kad Jūs iki galo suprastumėte, kaip mes naudojame Jūsų asmens duomenis, ir dėl to nepatirtumėte jokių
        nepatogumų. Jūs galite bet kada susisiekti su mumis ir pasiteirauti, ar mes tvarkome kokius nors Jūsų asmens
        duomenis. Jei mes saugome ar bet kokiu būdu naudojame Jūsų asmens duomenis, Jūs turite teisę su jais
        susipažinti. Norėdami tai padaryti, pateikite mums rašytinį prašymą šioje Privatumo politikoje nurodytu el.
        pašto adresu, patvirtinkite savo asmens tapatybę; teikiant tokį prašymą, taip pat maloniai prašome laikytis
        sąžiningumo ir protingumo principų.
      </p>

      <SpacedSubheading>15.2. Teisė atšaukti sutikimą</SpacedSubheading>
      <p>Jei esate pateikę mums aiškų sutikimą dėl Jūsų duomenų tvarkymo, jį galite bet kada atšaukti.</p>

      <SpacedSubheading>15.3. Papildomos teisės</SpacedSubheading>
      <p>
        Toliau pateikiame informaciją apie papildomas Jūsų turimas teises, kurias galite įgyvendinti laikydamiesi toliau
        aprašytos tvarkos.
      </p>
      <p>
        (a) Jūs turite teisę prašyti mūsų ištaisyti bet kokius turimų duomenų netikslumus. Tokiu atveju mes galime
        paprašyti Jūsų patvirtinti ištaisytą informaciją.
      </p>
      <p>
        (b) Jūs turite teisę prašyti mūsų ištrinti Jūsų asmens duomenis. Ši teisė įgyvendinama Bendrojo duomenų apsaugos
        reglamento (ES) 2016/679 17 straipsnyje numatytais atvejais.
      </p>
      <p>(c) Jūs turite teisę prašyti mūsų apriboti Jūsų asmens duomenų tvarkymą arba jų netvarkyti:</p>
      <ul>
        <li>
          laikotarpiu, kurio reikia tam, kad įsitikintumėme Jūsų asmens duomenų tikslumu, kai pateikiate pretenzijas dėl
          duomenų tikslumo;
        </li>
        <li>
          kai mūsų vykdomas Jūsų asmens duomenų rinkimas, saugojimas arba naudojimas yra neteisėtas, tačiau
          nusprendžiate neprašyti ištrinti duomenų;
        </li>
        <li>
          kai Jūsų asmens duomenys mums yra nebereikalingi, tačiau Jums jų reikia, kad galėtumėte nustatyti, įvykdyti
          arba apginti teisinį reikalavimą;
        </li>
        <li>
          laikotarpiu, kurio reikia tam, kad būtų nustatyta, ar mes turime svarbesnį teisinį pagrindą ir toliau tvarkyti
          Jūsų asmens duomenis, jei pasinaudojote savo teise išreikšti prieštaravimą dėl asmens duomenų tvarkymo.
        </li>
      </ul>
      <p>
        (d) Jūs turite teisę į duomenų, kurie tvarkomi automatizuotomis priemonėmis ir, kuriuos iš Jūsų gavome Jums
        sutinkant arba sutarties sudarymo tikslais, perkėlimą. Jums pasinaudojus šia teise, Jūsų prašymu perkelsime Jūsų
        pateiktų duomenų kopiją.
      </p>
      <p>
        (e) Jūs turite teisę BDAR 21 str. nustatyta tvarka nesutikti, kad mes naudotumėme Jūsų asmens duomenis. Jūs
        turite teisę nesutikti, kai Jūsų asmens duomenys tvarkomi teisėto intereso pagrindu (prie kiekvieno aukščiau
        nurodyto duomenų tvarkymo tikslo nurodyta kokiu pagrindu duomenys yra tvarkomi) arba tiesioginės rinkodaros
        tikslu.
      </p>

      <SpacedSubheading>15.4. Teisė prašyti suteikti daugiau informacijos</SpacedSubheading>
      <p>
        Tikimės, kad Jūs suprasite, jog aptarti visus įmanomus asmens duomenų rinkimo ir naudojimo būdus yra labai
        sunku. Mes stengiamės pateikti kuo aiškesnę ir išsamesnę informaciją bei įsipareigojame atnaujinti šią Privatumo
        politiką, pasikeitus asmens duomenų naudojimo procesui. Vis dėlto, jei turite kokių nors klausimų apie Jūsų
        asmens duomenų naudojimą, džiaugsimės galėdami į juos atsakyti arba suteiksime visą papildomą informaciją, kurią
        galime atskleisti. Jei turite kokių nors konkrečių klausimų arba nesupratote pateiktos informacijos, susisiekite
        su mumis.
      </p>

      <SpacedHeading>10. Nusiskundimai</SpacedHeading>
      <p>
        Jei manote, kad Jūsų, kaip Duomenų subjekto, teisės yra ir (ar) gali būti pažeistos, prašome nedelsiant kreiptis
        į mus šioje Privatumo politikoje nurodytu el. paštu. Mes užtikriname, kad tik gavus Jūsų skundą susisieksime su
        Jumis per pagrįstą laikotarpį ir informuosime apie skundo tyrimo eigą, o vėliau ir apie rezultatą.
      </p>
      <p>
        Jei tyrimo rezultatai Jūsų netenkins, galėsite pateikti skundą priežiūros institucijai - Valstybinei duomenų
        apsaugos inspekcijai (www.vdai.lrv.lt).
      </p>
      <SpacedHeading>11. Atsakomybė</SpacedHeading>
      <p>
        Jūs esate atsakingi už savo pateikiamų duomenų konfidencialumą taip pat už tai, kad Jūsų mums pateikti duomenys
        būtų tikslūs, teisingi ir išsamūs. Jeigu pasikeičia Jūsų pateikti duomenys, Jūs turite nedelsdamas mus apie tai
        informuoti el. paštu. Mes jokiu atveju nebūsime atsakingi už žalą, atsiradusią Jums dėl to, jog Jūs nurodėte
        neteisingus ar neišsamius asmens duomenis arba neinformavote mūsų jiems pasikeitus.
      </p>
      <SpacedHeading>12. Privatumo politikos pakeitimai</SpacedHeading>
      <p>
        Mes galime bet kada atnaujinti ar pakeisti šią Privatumo politiką. Jei pageidaujate gauti atnaujintą Privatumo
        politiką, prašome pranešti apie tai 2 skyriuje nurodytu el. pašto adresu.
      </p>
    </div>
  );
}
