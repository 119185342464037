import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import IMAGES from '../../assets/images';
import { strings } from '../../localization/strings';
import { useAuth } from '../../hooks/use-auth';
import CommonButtonDefault from '../common/buttons/default';
import { Category } from '../../entities/category';
import { getCategories } from '../../services/api/articles';
import LayoutSearchComponent from './search';

function LayoutHeaderComponent() {
  const { user, isAllowed, signout } = useAuth();
  const [categories, setCategories] = useState<Category[]>();
  const [selectedCategoryId] = useState<string>('6ca312a9-ccac-4a96-8001-eae810972956');
  const navigate = useNavigate();

  const onSignOutClick = () => {
    signout();
    navigate('/');
  };

  const userNavigation = [
    // { name: strings.profile.yourProfile, href: '/users/profile/dashboard' },
    { name: strings.profile.settings, href: '/users/profile/settings' },
  ];

  if (isAllowed('get-users-get')) {
    userNavigation.push({ name: strings.profile.users, href: '/admin/roles' });
  }

  if (isAllowed('get-articles-reported')) {
    userNavigation.push({ name: strings.profile.reports, href: '/admin/reports' });
  }

  if (isAllowed('get-comments-list')) {
    userNavigation.push({ name: strings.profile.comments, href: '/admin/comments' });
  }

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await getCategories();

      setCategories(res);
    };

    fetchCategories();
  }, []);

  return (
    <Popover
      as="header"
      className={({ open }) =>
        classNames(
          open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
          'bg-white shadow-sm lg:static lg:overflow-y-visible',
        )
      }
    >
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-2 md:py-0">
            <div className="relative flex justify-between lg:gap-8 xl:grid xl:grid-cols-12">
              <div className="flex xl:col-span-2">
                <div className="flex flex-shrink-0 items-center">
                  <a href="https://www.napo.lt">
                    <img className="block h-10 lg:h-12 w-auto" src={IMAGES.LOGOTYPES.PRIMARY} alt="NAPO" />
                  </a>
                </div>
              </div>
              <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                <div className="flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
                  <LayoutSearchComponent />
                </div>
              </div>
              <div className="flex items-center md:absolute md:inset-y-0 md:right-0 lg:hidden">
                {/* Mobile menu button */}
                <Popover.Button className="-mx-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500">
                  <span className="sr-only">{strings.header.menu.open}</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Popover.Button>
              </div>
              <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                {user ? (
                  <Menu as="div" className="relative ml-5 flex-shrink-0 ">
                    <div>
                      <Menu.Button className="flex items-center space-x-4 rounded-full bg-white focus:outline-none ring-2 ring-nIndigo-600 py-2 px-5 focus:ring-Indigo-600 focus:ring-offset-2 text-nIndigo-600">
                        <span className="sr-only">{strings.header.userMenu.open}</span>
                        <span>
                          <span className="icon-profile inline-block mr-1" aria-hidden="true" />
                          {user.firstName}
                        </span>
                        <span className="icon-gear" aria-hidden="true" />
                      </Menu.Button>
                    </div>
                    <Transition
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700',
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                        <Menu.Item>
                          <button
                            className="block px-4 py-2 text-sm text-gray-700"
                            type="button"
                            onClick={onSignOutClick}
                          >
                            {strings.profile.signOut}
                          </button>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <>
                    <a href="/users/register" className="ml-3 inline-flex items-center">
                      {/* rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600 */}
                      <CommonButtonDefault secondary xl>
                        {strings.header.registerButton}
                      </CommonButtonDefault>
                    </a>
                    <a href="/users/login" className="ml-3 inline-flex items-center">
                      <CommonButtonDefault primary xl>
                        {strings.header.loginButton}
                      </CommonButtonDefault>
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>

          <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
            <div className="mx-auto max-w-3xl space-y-1 px-2 pb-3 pt-2 sm:px-4">
              {categories &&
                categories.map((item) => (
                  <a
                    key={item.id}
                    href=""
                    aria-current={item.id === selectedCategoryId ? 'page' : undefined}
                    className={classNames(
                      item.id === selectedCategoryId ? 'bg-gray-100 text-gray-900' : 'hover:bg-gray-50',
                      'block rounded-md py-2 px-3 text-base font-medium',
                    )}
                  >
                    {item.title}
                  </a>
                ))}
            </div>
            <div className="border-t border-gray-200 pt-4">
              {user ? (
                <div>
                  <div className="mx-auto flex max-w-3xl items-center px-4 sm:px-6">
                    {/* <div className="flex-shrink-0"> */}
                    {/*  <img className="h-10 w-10 rounded-full" src={loggedUser.imageUrl} alt="" /> */}
                    {/* </div> */}
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">
                        {user?.firstName} {user?.lastName}
                      </div>
                      <div className="text-sm font-medium text-gray-500">{user?.email}</div>
                    </div>
                  </div>
                  <div className="mx-auto mt-3 max-w-3xl space-y-1 px-2 sm:px-4">
                    {userNavigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="px-4 sm:px-6 space-y-4">
                  <a href="/users/register">
                    {/* rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600 */}
                    <CommonButtonDefault secondary xl className="w-full mb-4">
                      {strings.header.registerButton}
                    </CommonButtonDefault>
                  </a>
                  <a href="/users/login">
                    <CommonButtonDefault primary xl className="w-full">
                      {strings.header.loginButton}
                    </CommonButtonDefault>
                  </a>
                </div>
              )}
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}

export default LayoutHeaderComponent;
