import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';

import { ModalKeys } from '../../context/modals';
import ModalText from './text-modal';
import { useModal } from '../../hooks/use-modal';
import RegisterBenefits from '../common/register/register-benefits';
import { useAuth } from '../../hooks/use-auth';

const COOKIE_NAME = 'hide_register_modal';

export default function ModalRegister() {
  const { params } = useModal();
  const { user } = useAuth();

  const { setVisible } = useModal();
  const cookies = new Cookies();

  useEffect(() => {
    const hideRegisterModal = cookies.get(COOKIE_NAME);

    if (!user && hideRegisterModal !== '1') {
      setVisible(ModalKeys.Register, true);
    }
  }, []);

  return (
    <ModalText md type={ModalKeys.Register} trackCloseCookie={COOKIE_NAME}>
      <RegisterBenefits />
    </ModalText>
  );
}
