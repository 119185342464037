import React, { createContext, useState, useCallback, useMemo, useEffect } from 'react';

import { Comment } from '../entities/comment';
import { Article } from '../entities/article';
import { useAnonymousComments } from '../hooks/use-anonymous-comments';

interface ComponentProps {
  children: React.ReactNode;
  comments: Comment[];
  article?: Article;
  parentId?: string;
  showAll?: boolean;
  onModifyComments?: (comments: Comment[], commentsLogged: Comment[]) => void;
}

interface ContextValue {
  isCommenting: boolean;
  setIsCommenting: (state: boolean) => void;
  setComments: (comments: Comment[]) => void;
  comments: Comment[];
  article?: Article;
  parentId?: string;
  addComments: (comments: Comment[]) => void;
  removeComment: (comment: Comment) => void;
  // addReplies: (comment: Comment, replies: Comment[]) => void;
  updateComment: (id: string, comment: Partial<Comment>) => void;
}

const CommentsContext = createContext<ContextValue>({
  isCommenting: false,
  setIsCommenting: () => null,
  setComments: () => null,
  addComments: () => null,
  removeComment: () => null,
  // addReplies: () => null,
  updateComment: () => null,
  comments: [],
  article: undefined,
  parentId: undefined,
});

const filterComments = (comments: Comment[], isAnonymous: boolean) => {
  if (isAnonymous) {
    return comments;
  }

  return comments.filter((item) => item.userId || item.isOwn);
};

function CommentsContextProvider({ children, comments, article, parentId, showAll, onModifyComments }: ComponentProps) {
  const [isCommenting, setIsCommenting] = useState<boolean>(false);
  const [visibleComments, setVisibleComments] = useState<Comment[]>(comments || []);
  const [allComments, setAllComments] = useState<Comment[]>(comments || []);
  const { showAnonymousComments } = useAnonymousComments();

  useEffect(() => {
    const filteredComments = filterComments(allComments, showAll || showAnonymousComments) || [];

    setVisibleComments(filteredComments);
    if (onModifyComments) {
      onModifyComments(allComments, filteredComments);
    }
  }, [showAnonymousComments, allComments]);

  const addComments = useCallback(
    (newComments: Comment[]) => {
      setAllComments([...(allComments || []), ...newComments]);
    },
    [allComments],
  );

  const removeComment = useCallback(
    (comment: Comment) => {
      allComments.splice(allComments.indexOf(comment), 1);

      setAllComments([...allComments]);
    },
    [allComments],
  );

  const updateComment = useCallback(
    (id: string, comment: Partial<Comment>) => {
      const originalComment = allComments.find((item) => item.id === id);

      if (originalComment) {
        const originalCommentIndex = allComments.indexOf(originalComment);

        allComments[originalCommentIndex] = { ...originalComment, ...comment };
      }

      setAllComments([...allComments]);
    },
    [allComments],
  );

  const contextValue = useMemo(
    () => ({
      isCommenting,
      setIsCommenting,
      comments: visibleComments,
      setComments: setAllComments,
      addComments,
      removeComment,
      updateComment,
      article,
      parentId,
    }),
    [
      isCommenting,
      setIsCommenting,
      visibleComments,
      addComments,
      removeComment,
      updateComment,
      setAllComments,
      article,
      parentId,
    ],
  );

  return <CommentsContext.Provider value={contextValue}>{children}</CommentsContext.Provider>;
}

CommentsContextProvider.defaultProps = {
  article: undefined,
  parentId: undefined,
  onModifyComments: undefined,
  showAll: false,
};

export { CommentsContext, CommentsContextProvider };
