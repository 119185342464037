import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import Cookies from 'universal-cookie';

import ContainerMd from '../../components/common/containers/containerMd';
import { getArticles } from '../../services/api/articles';
import { Article } from '../../entities/article';
import { strings } from '../../localization/strings';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import ArticleListItemComponent from '../../components/articles/article-list-item';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import CommonButtonDefault from '../../components/common/buttons/default';
import { CommentsContextProvider } from '../../context/comments';
import BannerArticlesListComponent from '../../components/banner/articles-list';
import { useAuth } from '../../hooks/use-auth';
import { useModal } from '../../hooks/use-modal';
import { ModalKeys } from '../../context/modals';
import ModalRegister from '../../components/modal/register-modal';

export default function ArticlesMainFeedComponent() {
  type SelectOptionTypes = {
    label: string;
    value: string;
  };
  const tabs: SelectOptionTypes[] = [
    { value: 'new', label: strings.article.sortBy.new },
    { value: 'popular', label: strings.article.sortBy.popular },
    { value: 'commented', label: strings.article.sortBy.commented },
  ];

  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  // const [lastArticle, setLastArticle] = useState<Article | undefined>(undefined);
  const [articles, setArticles] = useState<Article[]>([]);
  const navigate = useNavigate();

  const { categoryId, slug } = useParams();
  const [queryParams] = useSearchParams();
  const { token, user } = useAuth();

  const sortBy = queryParams.get('sort') || 'new';

  useEffect(() => {
    try {
      const fetchArticles = async () => {
        setIsLoading(true);
        const res = await getArticles(token, {
          categoryId,
          sort: queryParams.get('sort') || 'new',
        });

        setIsLoading(false);
        setArticles(res);
      };

      fetchArticles();
    } catch (e) {
      setIsLoading(false);
      setError((e as Error).message);
    }
  }, [categoryId, queryParams, token]);

  const loadMoreArticles = async () => {
    const lastArticle = articles[articles.length - 1];

    setIsLoadingMore(true);

    const res = await getArticles(token, {
      categoryId,
      sort: queryParams.get('sort') || 'new',
      lastArticleId: lastArticle?.id,
      lastArticlePublishedAt: lastArticle?.publishedAt,
    });

    setArticles([...articles, ...res]);
    setIsLoadingMore(false);
  };

  const changeSort = (sort: string) => {
    if (categoryId) {
      navigate(`/articles/${slug}/${categoryId}?sort=${sort}`);
    } else {
      navigate(`/?sort=${sort}`);
    }
  };

  const updateArticleVotes = (id: string, ratingDown: number, ratingUp: number, userVote?: number) => {
    const originalArtical = articles.find((item) => item.id === id);

    if (originalArtical) {
      originalArtical.ratingUp = ratingUp;
      originalArtical.ratingDown = ratingDown;
      originalArtical.userVote = userVote;
      setArticles([...articles]);
      // console.log(originalArtical.ratingDown);
    }
  };

  return (
    <>
      <ContainerMd>
        <div className="sm:hidden">
          <label htmlFor="sort-by-tabs" className="sr-only">
            {strings.tabs.label}
          </label>

          <Select
            isSearchable={false}
            id="sort-by-tabs"
            options={tabs}
            className="react-select-container"
            value={tabs.find((option) => option.value === sortBy) || tabs[0]}
            onChange={(e) => {
              if (e) {
                changeSort(e.value);
              }
            }}
          />
        </div>
        <div className="hidden sm:block">
          <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
            {tabs.map((tab, tabIdx) => (
              <button
                type="button"
                key={tab.label}
                onClick={() => changeSort(tab.value)}
                className={classNames(
                  tab.value === sortBy ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                  tabIdx === 0 ? 'rounded-l-lg' : '',
                  tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                  'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-center text-sm font-medium hover:bg-gray-50 focus:z-10',
                )}
              >
                <span>{tab.label}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tab.value === sortBy ? 'bg-nOrange-300' : 'bg-transparent',
                    'absolute inset-x-0 bottom-0 h-0.5',
                  )}
                />
              </button>
            ))}
          </nav>
        </div>

        <div className="mt-4">
          <h1 className="sr-only">{strings.article.newArticles}</h1>
          {error && (
            <div className="container max-w-4xl px-4 mx-auto pb-10 mt-16">
              <AlertPrimary text={error} type="danger" />
            </div>
          )}
          {isLoading ? (
            <div className="py-5 flex align-middle justify-center">
              <CommonAnimatedSpinner />
            </div>
          ) : (
            <ul className="space-y-4">
              {articles &&
                articles.map((article, index) => (
                  <CommentsContextProvider
                    key={`article-${article.externalId}`}
                    comments={article.comments}
                    article={article}
                  >
                    <li>
                      <ArticleListItemComponent isListItem article={article} onUpdate={updateArticleVotes} />
                      <BannerArticlesListComponent index={index} />
                    </li>
                  </CommentsContextProvider>
                ))}
              {isLoadingMore ? (
                <div className="py-2 flex align-middle justify-center">
                  <CommonAnimatedSpinner />
                </div>
              ) : (
                <CommonButtonDefault
                  onClick={loadMoreArticles}
                  className="block w-full rounded-md bg-white px-3 py-2 text-center text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  {strings.article.showMore}
                </CommonButtonDefault>
              )}
            </ul>
          )}
        </div>
      </ContainerMd>
      <ModalRegister />
    </>
  );
}
