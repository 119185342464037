import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import StyledInput from '../common/form/styled-input';
import CommonButtonDefault from '../common/buttons/default';
import { strings } from '../../localization/strings';
import { deleteCurrentUser, updatePassword } from '../../services/api/users';
import StyledError from '../common/form/styled-error';
import AlertPrimary from '../common/alerts/alert-primary';
import CommonAnimatedSpinner from '../common/animated/spinner';
import { useAuth } from '../../hooks/use-auth';

interface Inputs {
  confirmation: string;
  common: string;
}

export default function DeleteUserForm() {
  const { token, signout } = useAuth();
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);
      const res = await deleteCurrentUser(token, data.confirmation);

      if (res) {
        setSuccess(true);
        signout();
        navigate('/');
      }
      setIsLoading(false);
    } catch (e) {
      if (e instanceof Error) {
        setIsLoading(false);
        setError('common', { message: e.message });
      }
    }
  };

  return isloading ? (
    <div className="py-5 flex align-middle justify-center">
      <CommonAnimatedSpinner />
    </div>
  ) : (
    <>
      <div className="mb-6">
        <p className="mt-1 text-sm leading-6 text-gray-400">{strings.profile.settingsPage.deleteAccount.modalText}.</p>
      </div>

      {success && <AlertPrimary type="success" title={strings.profile.settingsPage.deleteAccount.alertSuccess} />}
      {errors.common && <AlertPrimary type="danger" title={errors.common.message} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-5 gap-x-6 gap-y-6 sm:max-w-xl">
          <div className="col-span-full md:col-span-4">
            <div className="mt-2">
              <StyledInput
                fullWidth
                id="confirm"
                type="text"
                className={` ${errors.confirmation && `!border-red-500`} `}
                {...register('confirmation', { required: true })}
              />
              {errors.confirmation && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
            </div>
          </div>
        </div>

        <div className="mt-6">
          <CommonButtonDefault primary xl type="submit">
            {strings.profile.settingsPage.deleteAccount.buttonDelete}
          </CommonButtonDefault>
        </div>
      </form>
    </>
  );
}
