import { Client } from 'typesense';
import { autocomplete } from '@algolia/autocomplete-js';
import { createElement, Fragment } from 'react';
import { render } from 'react-dom';
import { AutocompleteOptions } from '@algolia/autocomplete-js/dist/esm/types';

import { parameters } from '../../constants/parameters';
import { strings } from '../../localization/strings';
import { SearchArticle, SearchArticleHighlight } from '../../entities/search-article';

export const getClient = () => {
  return new Client({
    nodes: [{ host: parameters.api.typesense.host || '', port: 443, protocol: 'https' }],
    apiKey: parameters.api.typesense.apiKey || '',
    connectionTimeoutSeconds: 2,
  });
};

export const createAutocomplete = (options: AutocompleteOptions<any>) => {
  return autocomplete({
    renderer: { createElement, Fragment },
    openOnFocus: false,
    placeholder: strings.form.search,
    translations: {
      detachedCancelButtonText: strings.form.cancel,
      clearButtonTitle: strings.form.clear,
      submitButtonTitle: strings.form.submitSearch,
    },
    // detachedCancelButtonText: 'a',
    render({ children }, root) {
      render(children, root);
    },
    ...options,
  });
};

export const highlightSnippetOrValue = <T>(item: SearchArticle<T>, attribute: string, defaultValue: string): string => {
  if (item.highlights) {
    const field = item.highlights.find((h: SearchArticleHighlight) => h.field === attribute);

    if (field) {
      return field.snippet || field.value;
    }
  }

  return defaultValue;
};
