import React, { useEffect, useRef } from 'react';

import { strings } from '../../localization/strings';
import { createAutocomplete } from '../../services/external/typesense';
import * as usersApi from '../../services/api/users';
import { useAuth } from '../../hooks/use-auth';

function UsersSearchComponent() {
  const { token } = useAuth();
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = createAutocomplete({
      container: containerRef.current,
      getSources: (({ query }: any) => {
        return [
          {
            sourceId: 'users',
            getItems: async () => {
              return usersApi.search(token, query);
            },
            getItemInputValue: ({ item }: any) => {
              return item.email;
            },
            templates: {
              item({ item, html }: any) {
                return html`<a className="flex space-x-3" href="/admin/roles/${item.id}">
                  <div>
                    <div className="text-md">${item.email}</div>
                    <div className="text-xs text-gray-500">${item.firstName} ${item.lastName}</div>
                  </div>
                </a>`;
              },
            },
          },
        ];
      }) as any,
    });

    return () => {
      search.destroy();
    };
  }, []);

  return (
    <div className="w-full">
      <label htmlFor="search" className="sr-only">
        {strings.form.search}
      </label>
      <div className="relative">
        <div ref={containerRef} />
      </div>
    </div>
  );
}

export default UsersSearchComponent;
