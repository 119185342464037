import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

import { Article } from '../../entities/article';
import { getDateWithTime } from '../../services/formatter/date';
import IMAGES from '../../assets/images';
import { strings } from '../../localization/strings';
import CommentsListComponent from '../comments/comments-list';

interface ComponentProps {
  article: Article;
  onUpdate?: (id: string, ratingDown: number, ratingUp: number) => void;
}

const ImgContainer = styled.div`
  ${tw`h-48 sm:h-56 sm:h-[360px] flex relative z-0 mt-3 bg-center bg-no-repeat bg-cover`}
`;

const Title = styled.h2`
  ${tw`mt-3 text-base font-medium text-gray-900`}
`;

export default function ArticleReportedComponent({ article, onUpdate }: ComponentProps) {
  return (
    <article
      className="bg-white px-4 py-6 shadow sm:rounded-lg sm:p-6"
      aria-labelledby={`question-title-${article.id}`}
    >
      <Title className="hover:text-nIndigo-600 mb-4" id={`question-title-${article.externalId}`}>
        <a className="d-block" href={`/articles/view/${article.slug}/${article.id}`}>
          {article.title}
        </a>
      </Title>
      <CommentsListComponent isReported level={0} />
    </article>
  );
}

ArticleReportedComponent.defaultProps = {
  onUpdate: () => null,
};
