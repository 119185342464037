import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';
import Cookies from 'universal-cookie';

import { strings } from '../../localization/strings';
import StyledError from '../common/form/styled-error';
import { FormComment } from '../../entities/forms/comment';
import * as commentsApi from '../../services/api/comments';
import CommonButtonDefault from '../common/buttons/default';
import { useComments } from '../../hooks/use-comments';
import CommonAnimatedLoader from '../common/animated/loader';
import { useAuth } from '../../hooks/use-auth';
import CKEditorInput from '../common/form/ckeditor';
import StyledInput from '../common/form/styled-input';
import { useModal } from '../../hooks/use-modal';
import ModalAnonymous from '../modal/anonymous-modal';

interface ComponentProps {
  level: number;
  isFullComponent?: boolean;
}

function CommentsFormComponent({ level, isFullComponent }: ComponentProps) {
  const formRef = useRef<HTMLTextAreaElement | HTMLDivElement | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { isCommenting, setIsCommenting, article, addComments, parentId } = useComments();
  const { ref: inViewRef, inView: isCommentingOnScreen } = useInView({ threshold: 0.5 });
  const isCommentingPrev = useRef(false);
  const [commentText, setCommentText] = useState('');

  const { register, handleSubmit, setError, clearErrors, formState, setValue, getValues } = useForm<FormComment>();
  const { user, token, isAllowed, setUserNameCookie, userName } = useAuth();
  const [showUsernameInput, setShowUsernameInput] = useState<boolean>(!userName);
  const { ref, ...commentField } = register('comment', { required: true });
  const usernameField = register('userName', { value: userName });
  const [isAnonymous, setIsAnonymous] = useState<boolean>(!user);

  const cookies = new Cookies();

  const hideAnonymousModal = cookies.get('hide_anonymous_modal');

  const { setVisible } = useModal();

  const onCommentLoggedClick = (e: any) => {
    e.preventDefault();
    if (user) {
      setIsAnonymous(false);
      if (user.firstName) {
        setShowUsernameInput(false);
      }
    } else if (window.parent) {
      window.parent.postMessage({ type: 'LoginAPI', action: 'showLoginEmbed' }, '*');
    }
  };

  const onCommentAnonymousClick = (e: any) => {
    e.preventDefault();
    setIsAnonymous(true);
    setShowUsernameInput(true);
  };

  useEffect(() => {
    if (isCommenting && !isCommentingPrev.current) {
      // const id = 'profilePhoto';
      // const yOffset = -10;
      // const element = document.getElementById(id);
      // const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      //
      // window.scrollTo({top: y, behavior: 'smooth'});

      // align element bottom to window bottom

      setTimeout(() => {
        // Find article element
        let element: HTMLElement | null = formRef.current;

        while (element && element.tagName !== 'ARTICLE') {
          if (element.parentElement) {
            element = element.parentElement;
          } else {
            break;
          }
        }

        if (element) {
          const y = window.scrollY + (element.getBoundingClientRect().bottom - window.innerHeight) + 15;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 300);

      formRef.current?.focus();
    }

    isCommentingPrev.current = isCommenting;
  }, [isCommenting]);

  useEffect(() => {
    if (isCommenting && !isCommentingOnScreen) {
      setIsCommenting(false);
    }
  }, [isCommentingOnScreen]);

  const onSubmit: SubmitHandler<FormComment> = async (data) => {
    try {
      setIsSaving(true);

      if (data.comment && article) {
        if (level > 0) {
          if (parentId) {
            const comment = await commentsApi.reply(
              parentId,
              article.id,
              data.comment,
              data.userName,
              isAnonymous ? '' : token,
            );

            if (!comment.userId) {
              comment.isOwn = true;
            }

            addComments([comment]);
            setValue('comment', '');
            setCommentText('');
          }
        } else {
          const comment = await commentsApi.create(article.id, data.comment, data.userName, isAnonymous ? '' : token);

          if (!comment.userId) {
            comment.isOwn = true;
          }

          addComments([comment]);
          setValue('comment', '');
          setCommentText('');
        }

        setUserNameCookie(data.userName);
      }

      setIsSaving(false);
    } catch (e) {
      setIsSaving(false);
      setError('comment', { message: (e as Error).message });
    }
  };

  const onStartCommenting = () => {
    setIsCommenting(true);
  };

  const onEditorChange = (value: string) => {
    setValue('comment', value);
    setCommentText(value);
  };

  const onUpdateUsername = () => {
    setShowUsernameInput(!showUsernameInput);
    setUserNameCookie(getValues('userName'));
  };

  if (isCommenting) {
    return (
      <>
        <form
          className={`${!isFullComponent && 'rounded-lg pb-2 border-nIndigo-100 border'} mt-3`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <label htmlFor="comment" className="sr-only">
            {strings.article.form.writeComment}
          </label>
          <div>
            {isAllowed('post-comments-publish-preformatted') ? (
              <div
                className={`${isFullComponent && 'border border-nIndigo-200'}`}
                ref={(e) => {
                  formRef.current = e;
                  inViewRef(e);
                }}
              >
                <CKEditorInput value={commentText} onChange={onEditorChange} />
              </div>
            ) : (
              <CKEditorInput value={commentText} minimal onChange={onEditorChange} />
            )}

            {formState.errors.comment && <StyledError>{formState.errors.comment.message}</StyledError>}
          </div>
          {formState.errors.common && <StyledError>{formState.errors.common.message}</StyledError>}
          <div
            className={`${
              !isFullComponent && 'border-t border-nIndigo-100 px-3'
            } w-full justify-between pt-2 flex flex-wrap`}
          >
            <div className="items-center flex w-full sm:w-auto">
              {!isAnonymous && user && user.firstName ? (
                <div className="flex items-center w-full sm:w-auto">
                  {user.imageUrl && (
                    <img className="h-8 w-8 rounded-full mr-2" src={user.imageUrl} aria-hidden="true" alt="" />
                  )}
                  <div className="font-bold text-xs">{user?.firstName}</div>
                </div>
              ) : (
                <div className="flex items-center w-full sm:w-auto flex-wrap">
                  {showUsernameInput ? (
                    <span className="inline-block w-[200px] mb-3">
                      <StyledInput fullWidth className="!rounded-md" type="text" {...usernameField} />
                      {formState.errors.userName && <StyledError>{strings.error.required}</StyledError>}
                    </span>
                  ) : (
                    <div className="font-bold text-xs">{userName}</div>
                  )}
                  {(isAnonymous || !user?.firstName) && (
                    <button type="button" onClick={onUpdateUsername}>
                      <span className="border-gray-700 text-[12px] inline-flex justify-center items-center border rounded-full h-5 w-5 ml-2 icon-edit-solid" />
                    </button>
                  )}
                </div>
              )}
            </div>

            <div className="flex items-center w-full sm:w-auto flex-wrap sm:space-y-0">
              {isFullComponent && (
                <div className="order-last sm:order-1 mr-2">
                  {isAnonymous ? (
                    <CommonButtonDefault className="text-right" textXs noWrap link onClick={onCommentLoggedClick}>
                      {strings.comments.commentLogged}
                    </CommonButtonDefault>
                  ) : (
                    <CommonButtonDefault className="text-right" textXs noWrap link onClick={onCommentAnonymousClick}>
                      {strings.comments.commentAnonymously}
                    </CommonButtonDefault>
                  )}
                </div>
              )}
              {isSaving && <CommonAnimatedLoader />}
              <div className="w-full sm:w-auto mt-2 sm:mt-0 mb-1 sm:mb-0 order-1 sm:order-2">
                <CommonButtonDefault
                  primary={!isFullComponent}
                  default={isFullComponent}
                  className={`!w-full !sm:w-auto ${isFullComponent && '!rounded-md'}`}
                  type="submit"
                  smallHorizontalPadding
                  disabled={isSaving}
                  onClick={() => clearErrors('common')}
                >
                  {strings.button.comment}
                </CommonButtonDefault>
              </div>
            </div>
          </div>
        </form>
        {!isFullComponent && <ModalAnonymous />}
      </>
    );
  }

  if (level === 0) {
    return (
      <div className="mt-3 border-t border-nIndigo-100 pt-3">
        <CommonButtonDefault articleComment onClick={onStartCommenting}>
          {user?.imageUrl && (
            <img className="h-[18px] w-[18px] rounded-full mr-2" src={user.imageUrl} aria-hidden="true" alt="" />
          )}
          {strings.article.writeComment}
        </CommonButtonDefault>
      </div>
    );
  }

  return null;
}

CommentsFormComponent.defaultProps = {
  isFullComponent: false,
};

export default CommentsFormComponent;
