import React, { useEffect, useRef } from 'react';

import { strings } from '../../localization/strings';
import { SearchArticle, SearchArticleDocument } from '../../entities/search-article';
import { createAutocomplete, getClient, highlightSnippetOrValue } from '../../services/external/typesense';

const typesenseClient = getClient();

function LayoutSearchComponent() {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = createAutocomplete({
      container: containerRef.current,
      getSources: (({ query }: any) => {
        return [
          {
            sourceId: 'articles',
            getItems: async () => {
              const res = await typesenseClient
                .collections<SearchArticle<SearchArticleDocument>>('articles')
                .documents()
                .search({
                  q: query,
                  query_by: 'title, excerpt',
                  highlight_full_fields: 'title',
                  highlight_start_tag: '__aa-highlight__',
                  highlight_end_tag: '__/aa-highlight__',
                });

              return res.hits;
            },
            getItemInputValue: ({ item }: any) => {
              return item.document.title;
            },
            templates: {
              item({ item, components, html }: any) {
                const title = highlightSnippetOrValue(item, 'title', item.document.title);
                const excerpt = highlightSnippetOrValue(item, 'excerpt', item.document.excerpt);

                return html`<a
                  className="flex space-x-3"
                  href="/articles/view/${item.document.slug}/${item.document.id}"
                >
                  <img
                    src="${item.document.imageUrl}"
                    className="h-12 w-12 shrink-0 object-cover"
                    aria-hidden="true"
                    alt=""
                  />
                  <div>
                    <div className="text-md">
                      ${components.Highlight({
                        hit: { title },
                        attribute: 'title',
                      })}
                    </div>
                    <div className="text-xs text-gray-500">
                      ${components.Highlight({
                        hit: { excerpt },
                        attribute: 'excerpt',
                      })}
                    </div>
                  </div>
                </a>`;
              },
            },
          },
        ];
      }) as any,
    });

    return () => {
      search.destroy();
    };
  }, []);

  return (
    <div className="w-full">
      <label htmlFor="search" className="sr-only">
        {strings.form.search}
      </label>
      <div className="relative">
        <div ref={containerRef} />
      </div>
    </div>
  );
}

export default LayoutSearchComponent;
