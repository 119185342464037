import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ContainerMd from '../../components/common/containers/containerMd';
import { getArticle } from '../../services/api/articles';
import { Article } from '../../entities/article';
import { strings } from '../../localization/strings';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import ArticleListItemComponent from '../../components/articles/article-list-item';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import { CommentsContextProvider } from '../../context/comments';
import { getComments } from '../../services/api/comments';
import { useAuth } from '../../hooks/use-auth';
import { Comment } from '../../entities/comment';

export default function ArticleViewComponent() {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [article, setArticle] = useState<Article>();

  const { token } = useAuth();
  const { articleId } = useParams();

  useEffect(() => {
    try {
      const fetchArticle = async () => {
        if (articleId) {
          setIsLoading(true);

          const item = await getArticle(token, articleId);
          const comments = await getComments(token, articleId);

          setIsLoading(false);
          item.comments = comments;

          setArticle(item);
        }
      };

      fetchArticle();
    } catch (e) {
      setIsLoading(false);
      setError((e as Error).message);
    }
  }, [articleId, token]);

  const updateArticleVotes = (id: string, ratingDown: number, ratingUp: number, userVote?: number) => {
    if (article) {
      setArticle({ ...article, ratingDown, ratingUp, userVote });
    }
  };

  const updateArticleComments = (comments: Comment[], commentsLogged: Comment[]) => {
    if (article) {
      setArticle({ ...article, totalComments: comments.length, totalCommentsLogged: commentsLogged.length });
    }
  };

  return (
    <ContainerMd>
      <div className="">
        <h1 className="sr-only">{strings.article.newArticles}</h1>
        {error && (
          <div className="container max-w-4xl px-4 mx-auto pb-10 mt-16">
            <AlertPrimary text={error} type="danger" />
          </div>
        )}
        {isLoading && (
          <div className="py-5 flex align-middle justify-center">
            <CommonAnimatedSpinner />
          </div>
        )}
        {article && (
          <CommentsContextProvider
            comments={article?.comments}
            article={article}
            onModifyComments={updateArticleComments}
          >
            <ArticleListItemComponent article={article} onUpdate={updateArticleVotes} />
          </CommentsContextProvider>
        )}
      </div>
    </ContainerMd>
  );
}
