import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import { strings } from '../../localization/strings';
import Heading1 from '../../components/common/headings/heading1';
import RoundedBlock from '../../components/common/blocks/roundedBlock';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import ProfileUpdateFormComponent from '../../components/profile/update-profile-form';
import ChangePasswordForm from '../../components/profile/change-password-form';
import DeleteUserComponent from '../../components/profile/delete-users';
import CommonButtonDefault from '../../components/common/buttons/default';
import AssignUserComponent from '../../components/profile/assign-user';

export default function ProfileSettingsPageComponent() {
  const { user, isAllowed, token, signout } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSignOutClick = () => {
    setIsLoading(true);
    signout();
    navigate('/');
  };

  return (
    <div className="px-4 sm:px-0">
      <RoundedBlock>
        <Heading1>{strings.profile.settingsPage.title}</Heading1>
        {isLoading && <CommonAnimatedLoader />}
        <div className="divide-y divide-slate-200">
          {user && <ProfileUpdateFormComponent />}
          <ChangePasswordForm token={token} />

          {isAllowed('post-users-profile-update') && <AssignUserComponent />}
          {isAllowed('post-users-profile-delete') && <DeleteUserComponent />}
          <div className="pt-7">
            <CommonButtonDefault onClick={() => onSignOutClick()}>{strings.profile.signOut}</CommonButtonDefault>
          </div>
        </div>
      </RoundedBlock>
    </div>
  );
}
