import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import ContainerMd from '../../components/common/containers/containerMd';
import { strings } from '../../localization/strings';
import { useAuth } from '../../hooks/use-auth';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import { getReportedArticles } from '../../services/api/articles';
import { Article } from '../../entities/article';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import { CommentsContextProvider } from '../../context/comments';
import ArticleReportedComponent from '../../components/articles/article-reported';

export default function AdminReportsComponent() {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastArticle, setLastArticle] = useState<Article | undefined>(undefined);
  const [articles, setArticles] = useState<Article[]>([]);

  const { categoryId } = useParams();
  const [queryParams] = useSearchParams();
  const { token } = useAuth();

  useEffect(() => {
    try {
      const fetchArticles = async () => {
        setIsLoading(true);
        const res = await getReportedArticles(token, {
          lastArticleId: lastArticle?.id,
          lastArticlePublishedAt: lastArticle?.publishedAt,
        });

        setIsLoading(false);
        setArticles(res);
      };

      fetchArticles();
    } catch (e) {
      setIsLoading(false);
      setError((e as Error).message);
    }
  }, [categoryId, queryParams, lastArticle, token]);

  return (
    <ContainerMd>
      <div className="mt-4">
        <h1 className="sr-only">{strings.article.reportedArticles}</h1>
        {error && (
          <div className="container max-w-4xl px-4 mx-auto pb-10 mt-16">
            <AlertPrimary text={error} type="danger" />
          </div>
        )}
        {isLoading ? (
          <div className="py-5 flex align-middle justify-center">
            <CommonAnimatedSpinner />
          </div>
        ) : (
          <ul className="space-y-4">
            {articles && articles.length ? (
              articles.map((article) => (
                <CommentsContextProvider
                  key={`article-${article.externalId}`}
                  comments={article.reportedComments}
                  article={article}
                  showAll
                >
                  <li>
                    <ArticleReportedComponent article={article} />
                  </li>
                </CommentsContextProvider>
              ))
            ) : (
              <div className="py-5 flex align-middle justify-center">{strings.article.noReportedItems}</div>
            )}
          </ul>
        )}
      </div>
    </ContainerMd>
  );
}
