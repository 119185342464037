import React, { createContext, useState, useCallback, useMemo } from 'react';

import { SimpleObject } from '../entities/simple-object';

interface ComponentProps {
  children: React.ReactNode;
}

export const ModalKeys = {
  Privacy: 'Privacy',
  Terms: 'Terms',
  DeleteUser: 'DeleteUser',
  ShareArticle: 'ShareArticle',
  Register: 'Register',
  Anonymous: 'Anonymous',
};

interface ContextValue {
  setVisible: (name: string, state?: boolean) => void;
  isVisible: (name: string) => boolean;
  setParams: (params: SimpleObject) => void;
  params: SimpleObject;
}

const ModalsContext = createContext<ContextValue>({
  setVisible: () => null,
  isVisible: () => false,
  setParams: () => null,
  params: {},
});

function ModalsContextProvider({ children }: ComponentProps) {
  const [params, setParams] = useState<SimpleObject>({});
  const [isOpen, setOpen] = useState<SimpleObject<boolean>>({
    privacy: false,
    terms: false,
  });

  const setVisible = useCallback(
    (name: string, state = true) => {
      setOpen((prevState) => {
        return {
          ...prevState,
          [name]: state,
        };
      });
    },
    [setOpen],
  );

  const isVisible = useCallback(
    (name: string) => {
      return isOpen[name] || false;
    },
    [isOpen],
  );

  const contextValue = useMemo(
    () => ({
      setVisible,
      isVisible,
      setParams,
      params,
    }),
    [setVisible, isVisible, setParams, params],
  );

  return <ModalsContext.Provider value={contextValue}>{children}</ModalsContext.Provider>;
}

export { ModalsContext, ModalsContextProvider };
