export const strings = {
  anonymousUserPrefix: 'Vartotojas',
  roles: {
    administrator: 'Administratorius',
    editor: 'Redaktorius',
    moderator: 'Moderatorius',
    author: 'Autorius',
    top_commenter: 'Top komentatorius',
    commenter: 'Komentatorius',
  },
  and: 'ir',
  error: {
    required: 'Būtina sutikti',
    fieldIsRequired: 'Šis laukas yra privalomas',
    email: {
      required: 'El.pašto adresas yra privalomas',
      isUsed:
        'Šiuo el. pašto adresu jau yra sukurtas vartotojas. Prašome pasinaudoti prisijungimo forma arba naudoti kitą el. pašto adresą.',
    },
    password: {
      minLength: 'Slaptažodis turi būti bent {length} simbolių',
      same: 'Slaptažodžiai turi sutapti',
    },
    agreeTerms: {
      required: 'Būtina sutikti su naudojimosi taisyklėmis',
    },
    comment: {
      required: 'Neparašėte komentaro',
    },
    request: {
      common: 'Įvyko klaida bandykite vėliau',
    },
  },
  pageNotFound: {
    title: 'Puslapis nerastas',
    description: 'Patikrinkite puslapio adresą ir pamėginkite dar kartą.',
    goHome: 'Grįžti į pradžią',
  },
  loading: {
    wait: 'Palaukite..',
    waitComments: 'Komentarai kraunasi.....',
  },
  button: {
    close: 'Uždaryti',
    continue: 'Tęsti',
    sendReminder: 'Siųsti priminimo nuorodą',
    login: 'Prisijungti',
    createAccount: 'Registruotis',
    changePassword: 'Keisti slaptažodį',
    continueWithFacebook: 'Tęsti su Facebook',
    continueWithGoogle: 'Tęsti su Google',
    continueWithDelfi: 'Tęsti su DELFI',
    save: 'Išsaugoti',
    like: 'patinka',
    dislike: 'nepatinka',
    readMore: 'Skaityti daugiau',
    comment: 'Komentuoti',
    share: 'Dalintis',
    showAllComments: 'Rodyti visus komentarus',
    showMoreComments: 'Rodyti daugiau komentarų',
    upload: 'Įkelti',
    change: 'Keisti',
    block: 'Blokuoti',
    unblock: 'Atblokuoti',
    delete: 'Ištrinti',
    resolve: 'Komentaras geras',
    approveComment: 'Patvirtinti',
    copyToClipboard: 'Kopijuoti',
    loadOlderComments: 'Rodyti senesnius komentarus',
  },
  form: {
    password: 'Slaptažodis',
    email: 'El. paštas',
    surname: 'Pavardė',
    name: 'Vardas',
    oldPassword: 'Dabartinis slaptažodis',
    newPassword: 'Naujas slaptažodis',
    repeatPassword: 'Pakartokite naują slaptažodį',
    requiredFields: '* pažymėti laukai yra privalomi.',
    atLeastEightSymbols: 'Mažiausiai {length} simboliai',
    search: 'Ieškokite...',
    searchUser: 'Anketos paieška',
    cancel: 'Atšaukti',
    clear: 'Išvalyti',
    submitSearch: 'Ieškoti',
    or: 'arba',
  },
  header: {
    loginButton: 'Prisijungti',
    registerButton: 'Registruotis',
    menu: {
      open: 'Atidaryti meniu',
    },
    userMenu: {
      open: 'Atidaryti vartotojo meniu',
    },
  },
  agreement: {
    checkboxIHaveReadAnd: 'Aš perskaičiau ir sutinku su',
    checkboxPrivacyPolicy: 'Privatumo politika',
    checkboxAnd: 'ir',
    checkBoxTermsOfService: 'Paslaugų teikimo taisyklėmis',
    checkboxIAgreeToMarketing:
      'Sutinku gauti naujienas ir specialius pasiūlymus. Siųsime ne daugiau nei vieną laišką per savaitę. Jūs visada galėsite lengvai atsisakyti naujienų prenumeratos',
  },
  textPage: {
    privacyPolicy: {
      title: 'Privatumo politika',
      titleKuo: 'privatumo politika',
    },
    termsAndConditions: {
      title: 'Naudojimosi taisyklės',
      titleKuo: 'naudojimosi taisyklėmis',
    },
  },
  navigation: {
    signOut: 'Atsijungti',
  },
  registration: {
    title: 'Registracija',
    haveAccount: 'Esate užsiregistravęs?',
    login: 'Prisijunkite',
  },
  login: {
    title: 'Prisijungimas',
    forgotYourPassword: 'Pamiršote slaptažodį?',
    forgotYourPasswordLink: 'Priminti',
    dontHaveAccount: 'Neturite paskyros?',
    newMember: 'Esate naujas narys?',
    register: 'Registruokitės',
    agreementExplanation: 'Prisijungdami Jūs patvirtinate, kad sutinkate su mūsų ',
  },
  forgotPassword: {
    requestPasswordReset: 'Pamiršote slaptažodį?',
    text: 'Nurodykite el. pašto adresą, susietą su jūsų Napo paskyra, ir mes atsiųsime jums el. laišką su nuoroda, kad iš naujo nustatytumėte slaptažodį.',
    successTitle: 'Slaptažodžio priminimo nuoroda nusiųsta Jūsų įvestu el.pašto adresu',
    finishPasswordReset: 'Pasikeiskite slaptažodį',
    changePasswordSuccessTitle: 'Slaptažodis atnaujintas',
    linkToLogin: 'Grįžti į prisijungimą',
    helpText: 'Neturite el. pašto adreso arba reikia pagalbos prisijungiant?',
    help: 'Pagalba',
  },
  sidebar: {
    loginText: 'Susikurkite paskyrą ir diskutuokite Jums įdomiomis temomis su bendruomene',
    joinNapo: 'Prisijungti prie Napo',
    categories: 'Kategorijos',
    menu: {
      home: 'Į pagrindinį',
    },
  },
  tabs: {
    label: 'Pasirinkite naujienų rūšiavimą',
  },
  profile: {
    yourProfile: 'Tavo profilis',
    settings: 'Nustatymai',
    users: 'Vartotojai',
    reports: 'Pranešimai',
    comments: 'Komentarai',
    signOut: 'Atsijungti',
    edit: 'Redaguoti',
    settingsPage: {
      title: 'Profilio nustatymai',
      mainInfoTitle: 'Pagrindiniai nustatymai',
      changePassword: {
        title: 'Keisti slaptažodį',
        text: 'Čia galite atnaujinti slaptažodį, susietą su jūsų paskyra',
        linkText: 'Keisti slaptažodį.',
        alertSuccess: 'Slaptažodis sėkmingai pakeistas!',
      },
      updateMainInfo: {
        alertSuccess: 'Profilio nuotrauka sėkmingai atnaujinta!',
        fileFormats: 'JPG, GIF or PNG. 1MB max.',
      },
      deleteAccount: {
        title: 'Paskyros ištrynimas',
        buttonDelete: 'Ištrinti paskyrą',
        text: 'Ištrynus paskyrą Jūsų duomenys bus negrįžtamai panaikinti.',
        alertSuccess: 'Paskyra sėkmingai ištrinta!',
        modalText: 'Norėdami ištrinti paskyrą įveskite žodį TRINTI į žemiau esantį laukelį',
      },
      assignProfile: {
        disconnectSocialProfile: 'Atskirti paskyrą',
        disconnectFromGoogleProfile: 'Atskirti paskyrą nuo Google',
        disconnectFromFacebookProfile: 'Atskirti paskyrą nuo Facebook',
        title: 'Susietos paskyros',
        text: 'Prisijunkite su Google arba Facebook, jei norite turimas paskyras susieti su Napo paskyra.',
        facebook: 'Susieti su Facebook',
        gPlus: 'Susieti su Google',
      },
    },
  },
  article: {
    comment: 'Komentuoti',
    commentCount: 'Komentarų kiekis',
    writeComment: 'Rašyti komentarą',
    newArticles: 'Naujausi straipsniai',
    reportedArticles: 'Straipsniai su pranešimais apie komentarus',
    noReportedItems: 'Nėra pranešimų apie komentarus',
    showMore: 'Rodyti daugiau straipsnių',
    alertOnlyForUsers: 'Tik registruotiems nariams',
    form: {
      writeComment: 'Rašykite komentarą',
      placeholder: 'Jūsų komentaras...',
    },
    sortBy: {
      new: 'Naujausi',
      popular: 'Populiariausi',
      commented: 'Labiausiai komentuoti',
    },
    shareModal: {
      title: 'Dalintis',
    },
  },
  comments: {
    replies: 'atsakymą|atsakymus|atsakymų',
    reply: 'Atsakyti',
    voteUp: 'Patinka',
    showAll: 'Rodyti anoniminius komentarus',
    show: 'Rodyti',
    report: 'Pranešti',
    reportSuccess: 'Žinutė pranešta kaip kenksminga.',
    deleteSuccess: 'Žinutė sėkmingai ištrinta.',
    approveSuccess: 'Žinutė pažymėta kaip tinkama',
    commentAnonymously: 'Noriu komentuoti anonimiškai',
    commentLogged: 'Noriu komentuoti prisijungęs',
  },
  embed: {
    title: 'NAPO - Diskusijų portalas',
    text: 'Diskutuokite laisvai su',
    comments: 'Komentaras:|Komentarai:|Komentarų:',
    button: 'Diskutuoti',
  },
  admin: {
    title: 'Rolių nustatymai',
    lastLogIn: 'Paskutinis prisijungimas',
    neverLogged: 'Nebuvo prisijungęs',
    ipAdress: 'IP adresai',
    lastUpdated: 'Duomenys atnaujinti',
    neverUpdated: 'Nebuvo atnaujintas',
    roles: {
      searchForUser: 'Ieškoti vartotojo',
    },
    role: 'Rolė',
    categories: 'Kategorijos',
    cannotChangeRole: 'Šio vartotojo rolės keisti negalima',
  },
  modalRegister: {
    title: 'Susikurti paskyra verta, nes:',
    benefits: [
      'Turėsite nuolatinį diskusijų dalyvio vardą',
      'Naudositės išplėstinėmis turinio rašymo funkcijomis (emocijos, paryškinimai ir kt.)',
      'Jūsų komentarai taps labiau matomi',
    ],
    button: 'Registruotis',
    haveAccount: 'Esate užsiregistravęs?',
    login: 'Prisijunkite',
  },
  modalAnonymousCommenter: {
    title: 'Susikurti paskyra verta, nes:',
    description:
      'Pakeiskite savo anoniminį vardą į realų ar slapyvardį taip Jūsų komentaras taps labiau matomas visuomenei',
    button: 'Keisti',
  },
};
