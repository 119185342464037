import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';

import { login } from '../../services/api/users';
import { useAuth } from '../../hooks/use-auth';
import { strings } from '../../localization/strings';
import CommonAnimatedLoader from '../common/animated/loader';
import StyledLabel from '../common/form/styled-label';
import StyledInput from '../common/form/styled-input';
import StyledError from '../common/form/styled-error';
import CommonButtonDefault from '../common/buttons/default';
import { parameters } from '../../constants/parameters';
import AlertPrimary from '../common/alerts/alert-primary';

interface ComponentProps {
  orderHash?: string;
  prefillEmail?: string;
}

type Inputs = {
  common: string;
  email: string;
  password: string;
};

function UsersLoginForm({ prefillEmail }: ComponentProps) {
  const { setUserToken } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);
      const token = await login(data.email, data.password);

      setUserToken(token, false);

      navigate(parameters.firstPageUrl);
    } catch (e) {
      if (e instanceof Error) {
        // console.log(e.response);

        setIsLoading(false);
        setError('common', { message: e.message });
      }
    }
  };

  useEffect(() => {
    if (prefillEmail) {
      setValue('email', prefillEmail);
    }
  }, [prefillEmail, setValue]);

  return (
    <>
      {isLoading && <CommonAnimatedLoader />}
      {errors.common && <AlertPrimary type="danger" text={errors.common.message} />}
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="py-2">
          <StyledLabel htmlFor="email">{strings.form.email}</StyledLabel>
          <StyledInput
            fullWidth
            className={` ${errors.email && `!border-red-500`} `}
            type="email"
            {...register('email', { required: true })}
          />
          {errors.email && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
        </div>
        <div className="py-2">
          <StyledLabel htmlFor="password">{strings.form.password}</StyledLabel>
          <StyledInput
            fullWidth
            className={` ${errors.password && `!border-red-500`} `}
            type="password"
            {...register('password', { required: true })}
          />
          {errors.password && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
        </div>
        <div className="text-sm">
          {strings.login.forgotYourPassword}{' '}
          <Link to="/users/forgot-password" className="font-medium text-nIndigo-600 hover:underline">
            {strings.login.forgotYourPasswordLink}
          </Link>
        </div>

        <CommonButtonDefault
          wide
          xl
          primary
          className="mt-5 md:mt-7 mx-auto"
          type="submit"
          onClick={() => {
            clearErrors('common');
          }}
        >
          {strings.button.login}
        </CommonButtonDefault>
      </form>
    </>
  );
}

UsersLoginForm.defaultProps = {
  orderHash: '',
  prefillEmail: '',
};

export default UsersLoginForm;
