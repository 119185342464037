import React from 'react';
import tw, { styled } from 'twin.macro';

interface ComponentProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  secondary?: boolean;
  default?: boolean;
  red?: boolean;
  isButton?: boolean;
  link?: boolean;
  linkNext?: boolean;
  linShowMore?: boolean;
  disabled?: boolean;
  wide?: boolean;
  xl?: boolean;
  fb?: boolean;
  google?: boolean;
  commentLink?: boolean;
  articleComment?: boolean;
  delete?: boolean;
  commentReplyLink?: boolean;
  textOrange?: boolean;
  textXs?: boolean;
  smallHorizontalPadding?: boolean;
  noWrap?: boolean;
}

const Button = styled.button<ComponentProps>`
  ${tw`block text-md rounded-full leading-none py-3 font-bold`}
  ${tw`bg-gray-300 text-gray-900 hover:bg-gray-400`}
  ${(props) =>
    props.primary &&
    tw`!no-underline border border-nOrange-300  bg-nOrange-300 text-white hover:bg-orange-400 hover:border-orange-400`}
  ${(props) =>
    props.secondary &&
    tw`!no-underline border border-nIndigo-600 text-nIndigo-600 bg-white hover:bg-nIndigo-600 hover:text-white`}
  ${(props) => props.red && tw`!no-underline border border-nRed-600 bg-nRed-600 text-white hover:bg-red-700`}
  ${(props) =>
    props.default &&
    tw`!no-underline bg-nIndigo-600 border border-nIndigo-600 text-white bg-nIndigo-600 hover:bg-nIndigo-600 hover:text-white`}
  ${(props) => props.disabled && tw`opacity-25`}
  ${(props) => (props.wide ? tw`px-24 max-w-full` : tw`px-7 sm:px-9`)}
  ${(props) => props.textXs && tw`text-xs`}
  ${(props) => props.smallHorizontalPadding && tw`px-2 sm:px-4`}
  ${(props) => props.noWrap && tw`whitespace-nowrap`}
  ${(props) =>
    props.fb &&
    tw`w-full flex text-white bg-[#1877F2] hover:bg-[#3b5998] focus:ring-4 focus:outline-none focus:ring-[#3b5998] font-bold rounded-full text-md !px-4 py-3 items-center space-x-2 focus:ring-4 focus:outline-none`}
  ${(props) =>
    props.google &&
    tw`w-full flex text-slate-500 bg-white font-bold rounded-full text-md !px-4 py-3 space-x-2 items-center hover:bg-slate-50 focus:ring-4 focus:outline-none`}
  ${(props) =>
    props.linkNext &&
    tw`!p-0 text-blue-800 inline-block no-underline leading-5 bg-transparent hover:bg-transparent hover:underline hover:text-blue-500 rounded-none`}
  ${(props) =>
    props.linShowMore &&
    tw`!p-0 inline-block text-nIndigo-600 no-underline font-normal bg-transparent hover:bg-transparent hover:text-nIndigo-600 rounded-none`}
  ${(props) =>
    props.link &&
    tw`!p-0 font-normal rounded-none text-nIndigo-600 underline inline-block leading-6 bg-transparent hover:bg-transparent hover:no-underline hover:text-nIndigo-600`}
  ${(props) =>
    props.delete &&
    tw`!p-0 font-bold rounded-none text-nRed-600 inline-block leading-6 bg-transparent hover:bg-transparent hover:text-nRed-600`}
  ${(props) =>
    props.commentLink &&
    tw`!p-0 font-normal text-xs rounded-none text-nIndigo-300 inline-block leading-tight bg-transparent hover:bg-transparent hover:underline hover:text-nIndigo-600`}
  ${(props) =>
    props.commentReplyLink &&
    tw`!p-0 font-normal text-xs rounded-none text-nIndigo-600 inline-block underline leading-tight bg-transparent hover:bg-transparent hover:no-underline hover:text-nIndigo-600`}
  ${(props) =>
    props.articleComment &&
    tw`font-normal flex items-center text-left !px-2 w-full rounded-md text-nIndigo-300 border border-nIndigo-200 bg-nIndigo-50 hover:bg-nIndigo-100`}
  ${(props) =>
    props.textOrange &&
    tw`text-4xl lg:text-[44px] p-0 leading-none text-nOrange-300 font-medium bg-transparent rounded-none inline-block hover:bg-transparent hover:text-orange-500`}
  ${(props) => props.xl && tw`py-4`}
`;

function CommonButtonDefault({ children, ...rest }: ComponentProps) {
  return <Button {...rest}>{children}</Button>;
}

CommonButtonDefault.defaultProps = {
  isButton: true,
  primary: false,
  default: false,
  secondary: false,
  red: false,
  disabled: false,
  link: false,
  delete: false,
  linkNext: false,
  wide: false,
  xl: false,
  fb: false,
  google: false,
  commentLink: false,
  commentReplyLink: false,
  articleComment: false,
  linShowMore: false,
  textOrange: false,
  textXs: false,
  noWrap: false,
  smallHorizontalPadding: false,
};

export default CommonButtonDefault;
