import React, { ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/24/outline';
import Cookies from 'universal-cookie';

import { useModal } from '../../hooks/use-modal';
import { strings } from '../../localization/strings';

interface ComponentProps {
  children: ReactNode;
  title?: string;
  type: string;
  sm?: boolean;
  md?: boolean;
  trackCloseCookie?: string;
}

function ModalText({ type, title, children, sm, md, trackCloseCookie }: ComponentProps) {
  const { isVisible, setVisible } = useModal();
  const cookies = new Cookies();

  const onClose = () => {
    if (trackCloseCookie) {
      const currDay = new Date();
      const expiredAt = new Date(currDay.getTime() + 1000 * 60 * 60 * 24);

      cookies.set(trackCloseCookie, 1, {
        expires: expiredAt,
        path: '/',
      });
    }

    setVisible(type, false);
  };

  return (
    <Transition.Root show={isVisible(type)}>
      <Dialog
        as="div"
        className="text-dialog fixed z-10 inset-0 overflow-y-auto"
        onClose={() => setVisible(type, false)}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block lg:p-0">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div
            className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 2xl:max-w-6xl ${
              sm && 'md:w-[600px] w-full'
            } ${md && 'md:w-[760px] w-full'}
            ${!md && !sm && 'md:w-10/12'}
            `}
          >
            <div className="block absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => onClose()}
              >
                <span className="sr-only">{strings.button.close}</span>
                <XCircleIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-3">
              {title && (
                <Dialog.Title as="h1" className="text-lg leading-6 font-medium text-gray-900">
                  {title}
                </Dialog.Title>
              )}
              <div className="mt-2">{children}</div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

ModalText.defaultProps = {
  title: '',
  sm: false,
  md: false,
  trackCloseCookie: null,
};

export default ModalText;
