import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { strings } from '../../localization/strings';
import { useAuth } from '../../hooks/use-auth';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import CommonButtonDefault from '../../components/common/buttons/default';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import { getLatestComments } from '../../services/api/comments';
import { Comment } from '../../entities/comment';
import { getHumanDate } from '../../services/formatter/date';
import CommentsFeedbackButtonComponent from '../../components/comments/feedback-button';
import * as commentsApi from '../../services/api/comments';

export default function AdminCommentsComponent() {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [comments, setComments] = useState<Comment[]>([]);

  const { categoryId } = useParams();
  const [queryParams] = useSearchParams();
  const { token, isAllowed } = useAuth();

  useEffect(() => {
    const refreshInterval = setInterval(async () => {
      const latestComments = await getLatestComments(token, { firstCommentId: comments[0].id });

      if (latestComments.length) {
        setComments([...latestComments, ...comments]);
      }
    }, 1000 * 10);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [comments]);

  useEffect(() => {
    try {
      const fetchArticles = async () => {
        setIsLoading(true);
        const res = await getLatestComments(token, {});

        setIsLoading(false);
        setComments(res);
      };

      fetchArticles();
    } catch (e) {
      setIsLoading(false);
      setError((e as Error).message);
    }
  }, [categoryId, queryParams, token]);

  const loadMoreItems = async () => {
    setIsLoading(true);

    const lastItem = comments[comments.length - 1];

    const res = await getLatestComments(token, {
      lastCommentId: lastItem?.id,
      lastCommentCreatedAt: lastItem?.createdAt,
    });

    setComments([...comments, ...res]);
    setIsLoading(false);
  };

  const onResolveReport = async (comment: Comment) => {
    await commentsApi.resolve(token, comment.id, comment.articleId);

    const comm = comments.find((c) => c.id === comment.id);

    if (comm) {
      comm.reportsCount = 0;
      comm.status = 'approved';
      comm.reports = [];

      setComments([...comments]);
    }

    return true;
  };

  const onApproveComment = async (comment: Comment) => {
    await commentsApi.approveComment(token, comment.id);

    const comm = comments.find((c) => c.id === comment.id);

    if (comm) {
      comm.status = 'approved';

      setComments([...comments]);
    }

    return true;
  };

  const onDeleteComment = async (comment: Comment) => {
    await commentsApi.remove(token, comment.id);

    setComments([...comments.filter((c) => c.id !== comment.id)]);

    return false;
  };

  if (error) {
    return (
      <div className="container max-w-4xl px-4 mx-auto pb-10 mt-16">
        <AlertPrimary text={error} type="danger" />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="py-5 flex align-middle justify-center">
        <CommonAnimatedSpinner />
      </div>
    );
  }

  return comments && comments.length ? (
    <div className="flex flex-col w-full">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-900 w-[150px]">
              Date
            </th>
            <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-900 w-[90px]">
              Status
            </th>
            <th scope="col" className="whitespace-nowrap p-2 text-right text-sm font-semibold text-gray-900 w-[40px]">
              &nbsp;
            </th>
            <th scope="col" className="whitespace-nowrap p-2 text-right text-sm font-semibold text-gray-900 w-[40px]">
              &nbsp;
            </th>
            <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-900">
              Comment / Article
            </th>
            <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-900 w-[200px]">
              Username
            </th>
            <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-900">
              User IP
            </th>
            <th scope="col" className="whitespace-nowrap p-2 text-right text-sm font-semibold text-gray-900 w-[200px]">
              &nbsp;
            </th>
            {/* <th scope="col" className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"> */}
            {/*   <span className="sr-only">Edit</span> */}
            {/* </th> */}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {comments.map((comment) => (
            <tr
              key={comment.id}
              className={classNames(
                'highlighted-comment',
                comment.status === 'hide' ? 'bg-nIndigo-100 opacity-40' : '',
              )}
            >
              <td className="whitespace-nowrap p-2 text-sm text-gray-500">{getHumanDate(comment.createdAt)}</td>
              <td className="whitespace-nowrap p-2 text-sm text-gray-900">
                {comment.status === 'hide' && (
                  <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                    Hidden
                  </span>
                )}
                {comment.status === 'new' && (
                  <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                    New
                  </span>
                )}
                {comment.status === 'approved' && (
                  <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    Approved
                  </span>
                )}
              </td>
              <td className="whitespace-nowrap p-2 text-sm text-gray-500">
                {comment.reportsCount && comment.reportsCount > 0 ? (
                  <span className="w-4 h-4 shrink-0 grow-0 inline-flex justify-center items-center mr-1 rounded-full bg-nRed-600 !text-white text-xs leading-none">
                    {comment.reportsCount}
                  </span>
                ) : null}
              </td>
              <td className="whitespace-nowrap p-2 text-xs text-green-600">
                {comment.ratingUp > 0 && (
                  <>
                    <span className="icon-like" /> {comment.ratingUp}
                  </>
                )}
              </td>
              <td className="p-2 text-xs text-gray-900">
                <div dangerouslySetInnerHTML={{ __html: comment.text || '' }} />

                <a
                  className="d-block text-xs text-gray-300 mt-2"
                  target="_blank"
                  href={`/articles/view/${comment.articleSlug}/${comment.articleId}`}
                  rel="noreferrer"
                >
                  {comment.articleTitle}
                </a>
              </td>
              <td className="p-2 text-sm text-gray-500">{comment.userName}</td>
              <td className="whitespace-nowrap p-2 text-sm text-gray-500">{comment.ipAddress}</td>
              <td className="relative whitespace-nowrap p-2 text-right flex flex-row justify-end text-sm font-medium">
                {isAllowed('post-comments-resolve') && !!comment.reportsCount && (
                  <div className="ml-2">
                    <CommentsFeedbackButtonComponent onClick={() => onResolveReport(comment)}>
                      {strings.button.resolve}
                    </CommentsFeedbackButtonComponent>
                  </div>
                )}
                {!comment.reportsCount && comment.status !== 'approved' && isAllowed('post-comments-status') && (
                  <div className="ml-2">
                    <CommentsFeedbackButtonComponent
                      onClick={() => onApproveComment(comment)}
                      successMessage={strings.comments.approveSuccess}
                    >
                      {strings.button.approveComment}
                    </CommentsFeedbackButtonComponent>
                  </div>
                )}
                {isAllowed('post-comments-delete') && comment.status !== 'hide' && (
                  <div className="ml-2">
                    <CommentsFeedbackButtonComponent onClick={() => onDeleteComment(comment)}>
                      {strings.button.delete}
                    </CommentsFeedbackButtonComponent>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <CommonButtonDefault
        onClick={loadMoreItems}
        className="block w-full rounded-md bg-white px-3 py-2 text-center text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 mt-3"
      >
        {strings.button.loadOlderComments}
      </CommonButtonDefault>
    </div>
  ) : (
    <div> No comments </div>
  );
}
