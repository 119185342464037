import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { Comment } from '../../entities/comment';
import { CommentRating } from '../../entities/comment-rating';
import { SimpleObject } from '../../entities/simple-object';

export const getComments = async (
  token: string,
  id: string,
  parentId?: string,
  lastCommentId?: string,
): Promise<Comment[]> => {
  try {
    let url = `${parameters.api.baseUrl}/comments/article/${id}`;

    if (parentId) {
      url = url.concat(`?parentId=${parentId}`);
    }

    if (lastCommentId) {
      url = url.concat(`${url.indexOf('?') === -1 ? '?' : '&'}lastCommentId=${lastCommentId}`);
    }

    const result = await axios.get(url, { headers: { Authorization: `Bearer ${token || '---'}` } });

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const create = async (articleId: string, text: string, userName: string, token?: string): Promise<Comment> => {
  try {
    const url = `${parameters.api.baseUrl}/comments/publish`;

    const config = { headers: { Authorization: `Bearer ${token || '---'}` } };

    const result = await axios.post(url, { articleId, text, userName }, config);

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const reply = async (
  parentId: string,
  articleId: string,
  text: string,
  userName: string,
  token?: string,
): Promise<Comment> => {
  try {
    const url = `${parameters.api.baseUrl}/comments/reply`;

    const config = { headers: { Authorization: `Bearer ${token || '---'}` } };

    const result = await axios.post(url, { parentId, articleId, text, userName }, config);

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const vote = async (commentId: string, type: string, token: string): Promise<CommentRating> => {
  try {
    const url = `${parameters.api.baseUrl}/comments/vote/${type}/${commentId}`;

    const result = await axios.post(url, {}, { headers: { Authorization: `Bearer ${token}` } });

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const report = async (token: string, commentId: string, articleId: string) => {
  try {
    const url = `${parameters.api.baseUrl}/comments/report`;

    const result = await axios.post(
      url,
      { commentId, articleId },
      {
        headers: { Authorization: `Bearer ${token || '---'}` },
      },
    );

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const remove = async (token: string, commentId: string) => {
  try {
    const url = `${parameters.api.baseUrl}/comments/delete`;

    const result = await axios.post(
      url,
      { commentId },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

const changeCommentState = async (token: string, commentId: string, status: string) => {
  try {
    const url = `${parameters.api.baseUrl}/comments/status/${status}/${commentId}`;

    const result = await axios.post(
      url,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const resolve = async (token: string, commentId: string, articleId: string) => {
  try {
    const url = `${parameters.api.baseUrl}/comments/resolve`;

    const result = await axios.post(
      url,
      {
        commentId,
        articleId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const approveComment = async (token: string, commentId: string) => {
  return changeCommentState(token, commentId, 'approved');
};

export const hideComment = async (token: string, commentId: string) => {
  return changeCommentState(token, commentId, 'hidden');
};

export const getLatestComments = async (token: string, linkParams: SimpleObject): Promise<Comment[]> => {
  try {
    const url = `${parameters.api.baseUrl}/comments/list`;

    const result = await axios.get(url, { params: linkParams, headers: { Authorization: `Bearer ${token}` } });

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};
