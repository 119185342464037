import React from 'react';

import TextPrivacyPolicy from '../../components/text/privacy-policy';
import TextTermsConditions from '../../components/text/terms-conditions';
import { strings } from '../../localization/strings';
import Heading1 from '../../components/common/headings/heading1';
import ContainerMd from '../../components/common/containers/containerMd';

interface ComponentProps {
  type: string;
}

export default function TextPageComponent({ type }: ComponentProps) {
  if (type === 'privacy') {
    return (
      <ContainerMd>
        <Heading1>{strings.textPage.privacyPolicy.title}</Heading1>
        <TextPrivacyPolicy />
      </ContainerMd>
    );
  }

  if (type === 'terms') {
    return (
      <ContainerMd>
        <Heading1>{strings.textPage.termsAndConditions.title}</Heading1>
        <TextTermsConditions />
      </ContainerMd>
    );
  }

  return <div>Empty page</div>;
}
