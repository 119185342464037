import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { getTeaserArticle } from '../../services/api/articles';
import { Article } from '../../entities/article';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import TeaserComponent from '../../components/comments/teaser';
import { requestIframeResize } from '../../services/post-message';
import useWindowDimensions from '../../hooks/use-window-dimensions';

export default function CommentsTeaserComponent() {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [article, setArticle] = useState<Article>();
  const [queryParams] = useSearchParams();
  const height = useWindowDimensions();
  const { portal, articleId } = useParams();

  useEffect(() => {
    try {
      const fetchArticle = async () => {
        if (articleId && portal) {
          setIsLoading(true);

          const item = await getTeaserArticle(portal, articleId);

          setIsLoading(false);

          setArticle(item);
        }
      };

      fetchArticle();
    } catch (e) {
      setIsLoading(false);
      setError((e as Error).message);
    }
  }, [articleId, portal]);

  useEffect(() => {
    requestIframeResize(queryParams.get('targetElementId'));
  }, [height, queryParams]);

  return (
    <div>
      {error && <div>{error}</div>}
      {isLoading ? (
        <div className="py-5 flex align-middle justify-center">
          <CommonAnimatedSpinner />
        </div>
      ) : (
        <TeaserComponent amount={article?.totalComments || 0} />
      )}
    </div>
  );
}
