import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { Article } from '../../entities/article';
import { Category } from '../../entities/category';
import { ArticleVotes } from '../../entities/articleVotes';

export const getArticles = async (token: string, linkParams: object): Promise<Article[]> => {
  try {
    const url = `${parameters.api.baseUrl}/articles/list`;

    const result = await axios.get(url, { params: linkParams, headers: { Authorization: `Bearer ${token || '---'}` } });

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const getReportedArticles = async (token: string, linkParams: object): Promise<Article[]> => {
  try {
    const url = `${parameters.api.baseUrl}/articles/reported`;

    const result = await axios.get(url, { params: linkParams, headers: { Authorization: `Bearer ${token || '---'}` } });

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const getExternalArticle = async (token: string, portal: string, id: string): Promise<Article> => {
  try {
    const url = `${parameters.api.baseUrl}/articles/view/external/${portal}/${id}`;

    const result = await axios.get(url, { headers: { Authorization: `Bearer ${token || '---'}` } });

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const getTeaserArticle = async (portal: string, id: string): Promise<Article> => {
  try {
    const url = `${parameters.api.baseUrl}/articles/view/teaser/${portal}/${id}`;

    const result = await axios.get(url);

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const getArticle = async (token: string, id: string): Promise<Article> => {
  try {
    const url = `${parameters.api.baseUrl}/articles/view/${id}`;

    const result = await axios.get(url, { headers: { Authorization: `Bearer ${token || '---'}` } });

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const getCategories = async (): Promise<Category[]> => {
  try {
    const url = `${parameters.api.baseUrl}/articles/categories`;

    const result = await axios.get(url, {});

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const vote = async (articleId: string, type: string, token?: string): Promise<ArticleVotes> => {
  try {
    const url = `${parameters.api.baseUrl}/articles/vote/${type}/${articleId}`;

    const result = await axios.post(url, {}, { headers: { Authorization: `Bearer ${token}` } });

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};
