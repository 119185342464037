import React, { useState } from 'react';

import { Comment } from '../../entities/comment';
import CommonButtonDefault from '../common/buttons/default';
import { getHumanDate } from '../../services/formatter/date';
import { useComments } from '../../hooks/use-comments';
import { strings } from '../../localization/strings';
import * as commentsApi from '../../services/api/comments';
import { useAuth } from '../../hooks/use-auth';
import images from '../../assets/images';
import CommentsFeedbackButtonComponent from './feedback-button';

interface ComponentProps {
  comment: Comment;
  isShortComponent?: boolean;
  update?: (comment: Partial<Comment>) => void;
  deleteComment?: () => void;
}

function CommentComponent({ comment, isShortComponent, update, deleteComment }: ComponentProps) {
  const { setIsCommenting } = useComments();
  const { token, isAllowed, user } = useAuth();
  const [deleteError, setDeleteError] = useState<string>('');

  const replyToComment = () => {
    setIsCommenting(true);
  };

  const voteComment = async (id: string) => {
    const res = await commentsApi.vote(id, 'like', token);

    if (update) {
      update({ ratingUp: res.ratingUp, userVoted: res.voted });
    }

    return false;
  };

  const onResolveReport = async () => {
    await commentsApi.resolve(token, comment.id, comment.articleId);

    if (update) {
      update({ reportsCount: 0 });
    }

    return true;
  };

  const onApproveComment = async () => {
    await commentsApi.approveComment(token, comment.id);

    return true;
  };

  const onDeleteComment = async () => {
    try {
      const res = await commentsApi.remove(token, comment.id);

      if (res && comment && deleteComment) {
        deleteComment();
      }
    } catch (e) {
      setDeleteError((e as Error).message);
    }

    return false;
  };

  const reportComment = async (commentId: string, articleId: string) => {
    return commentsApi.report(token, commentId, articleId);
  };

  const getYoutubeId = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    }

    return null;
  };

  const getFacebookUrl = (url: string) => {
    if (url.match(/facebook\.com/)) {
      return url;
    }

    return null;
  };

  const replaceEmbeds = (text: string) => {
    return text.replace(/<oembed url="(.*?)"><\/oembed>/, (match, contents, offset, inputString) => {
      const youtubeId = getYoutubeId(contents);

      if (youtubeId) {
        return `<div class="embed-responsive"><iframe width="100%" src="https://www.youtube.com/embed/${youtubeId}" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div>`;
      }

      const facebookUrl = getFacebookUrl(contents);

      if (facebookUrl) {
        return `
    <div class="embed-responsive">
    <iframe
      src="https://www.facebook.com/plugins/post.php?href=${encodeURIComponent(
        facebookUrl,
      )}&width=500&show_text=true&appId=953221569024094&height=542"
      width="500"
      height="542"
      style="border:none;overflow:hidden"
      scrolling="no"
      frameBorder="0"
      allowFullScreen="true"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    />
</div>`;
      }

      return '';
    });
  };

  return (
    <div
      className={`text-sm ${isShortComponent && 'pb-4 border-b border-nIndigo-100'} ${
        comment.isHighlighted && 'py-1 px-2 bg-nBlue-20 border-nBlue-100 rounded-md border'
      } ${comment.isDelfi && 'py-1 px-2 bg-nGreen-20 border-nGreen-100 rounded-md border'}`}
    >
      <div className="flex space-x-2 text-xs">
        {comment.userPhoto && <img className="h-[18px] w-[18px] rounded-full" src={comment.userPhoto} alt="" />}
        {comment.isDelfi && !comment.userPhoto && (
          <div className="bg-nGreen-300 h-[18px] w-[18px] rounded-full flex items-center justify-center">
            <img className="h-[9px] w-auto" src={images.ICON.DELFIPLUS} alt="" />
          </div>
        )}
        {!comment.isDelfi && !comment.userPhoto && (
          <img className="h-[18px] w-[18px] rounded-full" src={images.AVATAR} alt="" />
        )}
        <b className={comment.isHighlighted ? 'text-nIndigo-600' : ''}>{comment.userName} </b>
        <span className="text-nIndigo-300">
          <time>{getHumanDate(comment.createdAt)}</time>
        </span>
      </div>
      <div className={`mt-1 ${comment.reportsCount && comment.reportsCount > 0 && 'text-nRed-600 flex items-center'}`}>
        {comment.reportsCount && comment.reportsCount > 0 ? (
          <span className="w-4 h-4 shrink-0 grow-0 inline-flex justify-center items-center mr-1 rounded-full bg-nRed-600 !text-white text-xs leading-none">
            {comment.reportsCount}
          </span>
        ) : null}
        <div className="comment">
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: replaceEmbeds(comment.text || '') }} />
        </div>
      </div>
      {!isShortComponent && (
        <div className="flex space-x-1.5 items-center mt-1">
          {user && (
            <>
              <CommentsFeedbackButtonComponent highlight={comment.userVoted} onClick={() => voteComment(comment.id)}>
                {strings.comments.voteUp}
              </CommentsFeedbackButtonComponent>
              <div className="w-1 h-1 bg-nIndigo-300 rounded-full" />
            </>
          )}
          <CommonButtonDefault commentReplyLink onClick={replyToComment}>
            {strings.comments.reply}
          </CommonButtonDefault>
          <div className="w-1 h-1 bg-nIndigo-300 rounded-full" />
          <CommentsFeedbackButtonComponent
            onClick={() => reportComment(comment.id, comment.articleId)}
            successMessage={strings.comments.reportSuccess}
          >
            {strings.comments.report}
          </CommentsFeedbackButtonComponent>
          {isAllowed('post-comments-delete') && (
            <>
              <div className="w-1 h-1 bg-nIndigo-300 rounded-full" />
              <CommentsFeedbackButtonComponent onClick={onDeleteComment}>
                {strings.button.delete}
              </CommentsFeedbackButtonComponent>
            </>
          )}
          {isAllowed('post-comments-resolve') && !!comment.reportsCount && (
            <>
              <div className="w-1 h-1 bg-nIndigo-300 rounded-full" />
              <CommentsFeedbackButtonComponent onClick={onResolveReport}>
                {strings.button.resolve}
              </CommentsFeedbackButtonComponent>
            </>
          )}
          {!comment.reportsCount && comment.status !== 'approved' && isAllowed('post-comments-status') && (
            <>
              <div className="w-1 h-1 bg-nIndigo-300 rounded-full" />
              <CommentsFeedbackButtonComponent
                onClick={onApproveComment}
                successMessage={strings.comments.approveSuccess}
              >
                {strings.button.approveComment}
              </CommentsFeedbackButtonComponent>
            </>
          )}
          {comment.ratingUp > 0 && (
            <div className={`text-xs !ml-auto ${comment.userVoted && 'text-nOrange-300'}`}>
              <span className="icon-like mr-1" />
              {comment.ratingUp}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

CommentComponent.defaultProps = {
  isShortComponent: false,
  update: () => null,
  deleteComment: () => null,
};

export default CommentComponent;
