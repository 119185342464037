import React, { createContext, useState, useCallback, useMemo } from 'react';
import Cookies from 'universal-cookie';

interface ComponentProps {
  children: React.ReactNode;
}

interface ContextValue {
  showAnonymousComments: boolean;
  toggleAnonymousComments: () => void;
}

const AnonymousContext = createContext<ContextValue>({
  showAnonymousComments: false,
  toggleAnonymousComments: () => null,
});

function AnonymousContextProvider({ children }: ComponentProps) {
  const cookies = new Cookies();
  const hasAnonymousCommentsCookie = cookies.get('showAnonymousComments');
  const initialState = typeof hasAnonymousCommentsCookie === 'undefined' ? true : hasAnonymousCommentsCookie === '1';

  const [showAnonymousComments, setShowAnonymousComments] = useState<boolean>(initialState);

  const expirationTime = new Date();

  expirationTime.setDate(expirationTime.getDate() + 61);

  const toggleAnonymousComments = useCallback(() => {
    cookies.set('showAnonymousComments', showAnonymousComments ? 0 : 1, { expires: expirationTime, path: '/' });
    setShowAnonymousComments(!showAnonymousComments);
  }, [showAnonymousComments]);

  const contextValue = useMemo(
    () => ({
      showAnonymousComments,
      toggleAnonymousComments,
    }),
    [showAnonymousComments, toggleAnonymousComments],
  );

  return <AnonymousContext.Provider value={contextValue}>{children}</AnonymousContext.Provider>;
}

export { AnonymousContext, AnonymousContextProvider };
