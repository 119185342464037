import { capitalizeFirstLetter } from './text';

const monthFormatter = new Intl.DateTimeFormat('lt', { month: 'long' });
const dateFormatter = new Intl.DateTimeFormat('lt', { day: '2-digit', month: '2-digit', year: 'numeric' });
const dateTimeFormatter = new Intl.DateTimeFormat('lt', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
});
const timeFormatter = new Intl.DateTimeFormat('lt', {
  hour: '2-digit',
  minute: '2-digit',
});

export const getLongMonthName = (month: number): string => {
  return capitalizeFirstLetter(monthFormatter.format(new Date(2000, month - 1, 1)));
};

export const getShortMonthName = (month: number, length = 3): string => {
  return getLongMonthName(month).substring(0, length);
};

export const getHumanDate = (timestamp: number): string => {
  const timeDiff = Date.now() - timestamp;

  if (timeDiff < 15000) {
    return 'ką tik';
  }

  const durations = [
    { name: 'sek.', diff: 60000, value: 1000 },
    { name: 'min.', diff: 3600000, value: 60000 },
    { name: 'val.', diff: Math.min(new Date().getHours(), 5) * 3600 * 1000, value: 3600000 },
  ];

  const duration = durations.find((d) => timeDiff < d.diff);

  if (duration) {
    return `prieš ${Math.round(timeDiff / duration.value)} ${duration.name}`;
  }

  const timeWithDay = [
    { name: '', diff: 0 },
    { name: 'vakar', diff: 86400000 },
    { name: 'užvakar', diff: 86400000 * 2 },
  ];

  for (const time of timeWithDay) {
    if (new Date(timestamp).toDateString() === new Date(Date.now() - time.diff).toDateString()) {
      return `${time.name ? `${time.name}, ` : ''} ${timeFormatter.format(new Date(timestamp))}`;
    }
  }

  return dateTimeFormatter.format(new Date(timestamp));
};

export const getDateWithTime = (timestamp: number): string => {
  return dateTimeFormatter.format(new Date(timestamp));
};

export const getDate = (timestamp: number): string => {
  return dateFormatter.format(new Date(timestamp));
};
