export const parameters = {
  isDevelopment: process.env.NODE_ENV === 'development',
  firstPageUrl: '/',
  registerPageUrl: '/users/register',
  loginPageUrl: '/users/login',
  password: {
    minLength: 8,
  },
  maxCommentsDepth: 2,
  adminRoles: ['ADMINISTRATOR', 'EDITOR', 'MODERATOR'],
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    websiteUrl: process.env.REACT_APP_WEBSITE_URL,
    typesense: {
      host: process.env.REACT_APP_TYPESENSE_HOST,
      apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
    },
  },
};
