import React from 'react';

import { ModalKeys } from '../../context/modals';
import { strings } from '../../localization/strings';
import ModalText from './text-modal';
import TextTermsConditions from '../text/terms-conditions';

export default function ModalTermsConditions() {
  return (
    <ModalText type={ModalKeys.Terms} title={strings.textPage.termsAndConditions.title}>
      <TextTermsConditions />
    </ModalText>
  );
}
