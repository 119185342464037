import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import * as usersService from '../../services/api/users';
import StyledInput from '../common/form/styled-input';
import { strings } from '../../localization/strings';
import StyledLabel from '../common/form/styled-label';
import StyledError from '../common/form/styled-error';
import CommonAnimatedLoader from '../common/animated/loader';
import CommonButtonDefault from '../common/buttons/default';
import { parameters } from '../../constants/parameters';
import { ModalKeys } from '../../context/modals';
import { useModal } from '../../hooks/use-modal';

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  agreeMarketing: boolean;
  agreeTerms: boolean;
};

function UsersRegisterFormComponent() {
  const { setUserToken } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setVisible } = useModal();

  const [firstName] = useState<string>();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);
      const token = await usersService.register({
        ...data,
      });

      if (window.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      setUserToken(token, false);
      navigate(parameters.firstPageUrl);
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError('email', { message: e.message });
      }
    }
  };

  const onOpenModal = (key: string) => {
    setVisible(key, true);
  };

  return isLoading ? (
    <CommonAnimatedLoader />
  ) : (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <StyledLabel htmlFor="name">{strings.form.name}</StyledLabel>
          <StyledInput
            {...register('firstName', { required: true })}
            fullWidth
            id="firstName"
            name="firstName"
            defaultValue={firstName}
            autoComplete="given-name"
            className={` ${errors.firstName && `!border-red-500`} `}
            type="text"
          />
          {errors.firstName && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
        </div>
        <div className="mt-5">
          <StyledLabel>{strings.form.surname}</StyledLabel>
          <StyledInput
            fullWidth
            className={` ${errors.lastName && `!border-red-500`} `}
            type="text"
            autoComplete="family-name"
            {...register('lastName', { required: true })}
          />
          {errors.lastName && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
        </div>
        <div className="mt-5">
          <StyledLabel>{strings.form.email}</StyledLabel>
          <StyledInput
            fullWidth
            className={` ${errors.email && `!border-red-500`} `}
            type="email"
            autoComplete="email"
            {...register('email', {
              required: true,
            })}
          />
          {errors.email && (
            <StyledError>
              {errors.email.message ? strings.error.email.isUsed : strings.error.email.required}
            </StyledError>
          )}
        </div>
        <div className="mt-5">
          <StyledLabel>{strings.form.password}</StyledLabel>
          <StyledInput
            fullWidth
            className={` ${errors.password && `!border-red-500`} `}
            type="password"
            {...register('password', { required: true, minLength: parameters.password.minLength })}
          />
          {errors.password && (
            <StyledError>
              {errors.password.type === 'required' && strings.error.fieldIsRequired}
              {errors.password.type === 'minLength' &&
                strings.error.password.minLength.replace('{length}', `${parameters.password.minLength}`)}
            </StyledError>
          )}
          <div className="text-gray-400 text-sm mt-2">
            {strings.form.atLeastEightSymbols.replace('{length}', `${parameters.password.minLength}`)}
          </div>
        </div>

        <div className="mt-5">
          <div className="flex items-center">
            <input
              className="h-4 w-4 focus:ring-indigo-500 border-gray-300 rounded checked:bg-blue-900 checked:hover:bg-blue-900"
              id="agreeTerms"
              type="checkbox"
              {...register('agreeTerms', { required: true })}
            />
            <label htmlFor="agreeTerms" className="ml-2 block text-sm align-middle leading-6 text-gray-900">
              {strings.agreement.checkboxIHaveReadAnd}{' '}
              <CommonButtonDefault className="!text-sm" link onClick={() => onOpenModal(ModalKeys.Privacy)}>
                {strings.agreement.checkboxPrivacyPolicy}
              </CommonButtonDefault>{' '}
              {strings.agreement.checkboxAnd}{' '}
              <CommonButtonDefault className="!text-sm" link onClick={() => onOpenModal(ModalKeys.Terms)}>
                {strings.agreement.checkBoxTermsOfService}
              </CommonButtonDefault>
            </label>
          </div>
        </div>
        {errors.agreeTerms && <StyledError>{strings.error.agreeTerms.required}</StyledError>}

        <div className="flex items-center mt-3">
          <div className="flex items-center h-5">
            <input
              className="h-4 w-4 focus:ring-indigo-500 border-gray-300 rounded checked:bg-blue-900 checked:hover:bg-blue-900"
              id="agreeMarketing"
              type="checkbox"
              {...register('agreeMarketing', {})}
            />
          </div>
          <label htmlFor="agreeMarketing" className="ml-2 text-sm text-gray-900">
            {strings.agreement.checkboxIAgreeToMarketing}
          </label>
        </div>

        <CommonButtonDefault wide xl primary className="mt-5 md:mt-7 mx-auto" type="submit">
          {strings.button.createAccount}
        </CommonButtonDefault>
      </form>
    </div>
  );
}

export default UsersRegisterFormComponent;
