import { useState, useEffect } from 'react';

function getContentHeight() {
  return window.document.body.scrollHeight;
}

export default function useWindowDimensions() {
  const [height, setHeight] = useState(getContentHeight());

  const watchSizeChanged = setInterval(() => {
    setHeight(getContentHeight());
  }, 500);

  useEffect(() => {
    function handleResize() {
      setHeight(getContentHeight());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.clearInterval(watchSizeChanged);
    };
  }, []);

  return height;
}
