import React from 'react';

interface ComponentProps {
  index: number;
}

export default function BannerArticlesListComponent({ index }: ComponentProps) {
  if (index === 0 || index % 5 !== 0) {
    return null;
  }

  return (
    <>
      <div id="adx-457213" />
      <script type="text/javascript">window._dlf.adxtag(457213)</script>
    </>
  );
}
