import React from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import ContainerMd from '../../components/common/containers/containerMd';
import { strings } from '../../localization/strings';

export default function ProfileDashboardPageComponent() {
  const { user } = useAuth();

  return (
    <>
      <div className="h-32 lg:w-full object-cover lg:h-48 bg-amber-200" />
      <div className="mx-auto max-w-5xl px-3 sm:pl-6 lg:pl-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
            {user?.imageUrl ? (
              <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src={user?.imageUrl} alt="" />
            ) : (
              <div className="h-24 w-24 bg-[#5560AB] rounded-full ring-4 ring-white sm:h-32 sm:w-32 text-white text-6xl justify-center items-center flex">
                {user?.firstName.charAt(0)}
              </div>
            )}
          </div>
          <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">
              <h1 className="truncate text-2xl font-bold text-gray-900">
                {user?.firstName} {user?.lastName}
              </h1>
              <div className="text-sm text-gray-500">{user?.email}</div>
            </div>
            <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
              <Link to="/users/profile/settings">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <span>{strings.profile.edit}</span>
                </button>
              </Link>
              {/*  <button */}
              {/*    type="button" */}
              {/*    className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" */}
              {/*  > */}
              {/*    <PhoneIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
              {/*    <span>Call</span> */}
              {/*  </button> */}
            </div>
          </div>
        </div>
        <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
          <h1 className="truncate text-2xl font-bold text-gray-900">
            {user?.firstName} {user?.lastName}
          </h1>
        </div>
      </div>
      <ContainerMd className="mt-10">Dashboardas prisijungusio vartotojo:</ContainerMd>
    </>
  );
}
