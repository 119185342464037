import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { strings } from '../../localization/strings';
import CommonButtonDefault from '../common/buttons/default';
import IMAGES from '../../assets/images';
import { pluralize } from '../../services/grammar/pluralize';

interface ComponentProps {
  amount: number;
}

function TeaserComponent({ amount }: ComponentProps) {
  const [queryParams] = useSearchParams();

  const onRedirect = () => {
    if (window.parent) {
      window.parent.location.href = queryParams.get('redirectUrl') || '';
    } else {
      document.location.href = queryParams.get('redirectUrl') || '';
    }
  };

  return (
    <div className="border-[3px] border-[#E2EAEE] rounded-[10px] bg-white py-4 px-5 leading-tight text-xs">
      <div className="flex flex-col sm:flex-row divide-y sm:divide-y-0 space-y-5 sm:space-y-0 sm:divide-x sm:space-x-5 divide-[#E2EAEE]">
        <div className="sm:w-[30%] justify-center flex">
          <div>
            {strings.embed.text}
            <a href="https://www.napo.lt" target="_blank" rel="noreferrer">
              <img className="block h-10 lg:h-12 w-auto" src={IMAGES.LOGOTYPES.PRIMARY} alt="NAPO" />
            </a>
          </div>
        </div>
        <div className="pt-3 sm:pt-0 sm:pl-3 sm:w-[30%] justify-center flex">
          <div>
            <div className="">{pluralize(amount, strings.embed.comments)}</div>
            <div>
              <CommonButtonDefault textOrange onClick={onRedirect}>
                {amount}
              </CommonButtonDefault>
            </div>
          </div>
        </div>
        <div className="pt-5 sm:pt-0 sm:w-[40%] sm:pl-5 lg:pl-7 items-center flex">
          <CommonButtonDefault className="w-full" default onClick={onRedirect}>
            {strings.embed.button}
          </CommonButtonDefault>
        </div>
      </div>
    </div>
  );
}

export default TeaserComponent;
