import React from 'react';

import { ModalKeys } from '../../context/modals';
import { strings } from '../../localization/strings';
import ModalText from './text-modal';
import ShareArticle from '../articles/article-share';
import { useModal } from '../../hooks/use-modal';

// interface ComponentProps {
//   articleUrl: string;
// }

export default function ModalShareArticle() {
  const { params } = useModal();

  return (
    <ModalText sm type={ModalKeys.ShareArticle} title={strings.article.shareModal.title}>
      <ShareArticle articleUrl={params.url} />
    </ModalText>
  );
}
