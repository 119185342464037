import React from 'react';

import CommonButtonDefault from '../common/buttons/default';
import IMAGES from '../../assets/images';
import { strings } from '../../localization/strings';
import { parameters } from '../../constants/parameters';

function UsersSocialButtonsComponent() {
  const onFacebookLoginClick = () => {
    window.location.href = `${parameters.api.baseUrl}/users/social/facebook`;
  };

  const onGoogleLoginClick = () => {
    window.location.href = `${parameters.api.baseUrl}/users/social/google`;
  };

  // const onDelfiLoginClick = () => {
  //   window.showLoginEmbed('login-modal', {
  //     modal: true,
  //     loginAnchorId: null,
  //     locale: 'lt', // Locales available: lt, en, ru
  //     successCallback() {
  //       // Callback function what to do after user successfully logs in
  //     },
  //   });
  // };

  return (
    <div>
      <div className="sm:w-[300px] mx-auto">
        <CommonButtonDefault type="button" fb className="mb-4" onClick={onFacebookLoginClick}>
          <img src={IMAGES.LOGOS.FB} aria-hidden="true" alt="" />
          <span>{strings.button.continueWithFacebook}</span>
        </CommonButtonDefault>
        <CommonButtonDefault className="drop-shadow mb-4" type="button" google onClick={onGoogleLoginClick}>
          <img src={IMAGES.LOGOS.GOOGLE} aria-hidden="true" alt="" />
          <span>{strings.button.continueWithGoogle}</span>
        </CommonButtonDefault>

        {/* <CommonButtonDefault className="drop-shadow" type="button" google onClick={onDelfiLoginClick}> */}
        {/*   <img src={IMAGES.LOGOS.DELFI} aria-hidden="true" alt="" /> */}
        {/*   <span>{strings.button.continueWithDelfi}</span> */}
        {/* </CommonButtonDefault> */}
      </div>
    </div>
  );
}

export default UsersSocialButtonsComponent;
