import React from 'react';

export default function BannerSidebarComponent() {
  return (
    <div>
      <div id="adx-528372" />
      <script type="text/javascript">window._dlf.adxtag(528372)</script>

      <div id="adx-528835" />
      <script type="text/javascript">window._dlf.adxtag(528835)</script>

      {/* <img className="block w-auto" src=" https://placehold.co/300x600" alt="banner" /> */}
      {/* <img className="block w-auto mt-4" src="https://placehold.co/300x250" alt="banner" /> */}
    </div>
  );
}
