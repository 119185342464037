import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import ContainerMd from '../../../components/common/containers/containerMd';
import { strings } from '../../../localization/strings';
import UsersSearchComponent from '../../../components/users/search';
import { listByRole } from '../../../services/api/users';
import { useAuth } from '../../../hooks/use-auth';
import CommonAnimatedSpinner from '../../../components/common/animated/spinner';
import Heading1 from '../../../components/common/headings/heading1';
import { CustomerRole } from '../../../entities/customer-role';
import images from '../../../assets/images';

export default function AdminRolesListComponent() {
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<CustomerRole[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      setIsLoading(true);

      const items = await listByRole(token);

      ReactDOM.unstable_batchedUpdates(() => {
        setIsLoading(false);
        setRoles(items);
      });
    };

    if (token) {
      fetchRoles();
    }
  }, [token]);

  return (
    <ContainerMd>
      <div className="p-5 bg-white rounded-xl">
        <Heading1>{strings.admin.roles.searchForUser}</Heading1>

        <UsersSearchComponent />
      </div>

      {isLoading && (
        <div className="py-5 flex align-middle justify-center">
          <CommonAnimatedSpinner />
        </div>
      )}

      {roles &&
        roles.map((role) => (
          <div className="p-5 mt-3 bg-white rounded-xl" key={role.role}>
            <Heading1>{role.role}</Heading1>
            {role.users.map((user) => (
              <div key={`user-${user.id}`}>
                <div className="relative flex items-center space-x-3 pb-3">
                  <div className="flex-shrink-0">
                    {user?.imageUrl ? (
                      <img
                        className="shrink-0 grow-0 h-14 w-14 bg-gray-200 rounded-full object-cover"
                        src={user.imageUrl}
                        alt={user.firstName}
                      />
                    ) : (
                      <img
                        src={images.AVATAR}
                        alt={user.firstName}
                        className="shrink-0 grow-0 h-14 w-14 bg-gray-200 rounded-full object-cover"
                      />
                    )}
                  </div>
                  <div className="min-w-0 flex-1">
                    <a href={`/admin/roles/${user.id}`} className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm font-medium text-gray-900">
                        {user.firstName} {user.lastName}
                      </p>
                      <p className="text-sm font-medium text-gray-900">{user.email}</p>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
    </ContainerMd>
  );
}
