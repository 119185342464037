import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import CommonButtonDefault from '../common/buttons/default';
import { strings } from '../../localization/strings';

interface ComponentProps {
  articleUrl: string;
}

export default function ShareArticle({ articleUrl }: ComponentProps) {
  return (
    <div className="sm:flex align-center sm:space-x-4">
      <p className="text-sm mb-4 sm:w-[70%] truncate border border-nIndigo-100 py-2 px-4 rounded-full">{articleUrl}</p>
      <div>
        <CopyToClipboard text={articleUrl}>
          <CommonButtonDefault>{strings.button.copyToClipboard}</CommonButtonDefault>
        </CopyToClipboard>
      </div>
    </div>
  );
}
