import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import UsersLoginPageComponent from '../users/login';
import ProfileDashboardPageComponent from '../profile/dashboard';
import TextPageComponent from '../text/page';
import ModalPrivacyPolicy from '../../components/modal/privacy-policy';
import ModalTermsConditions from '../../components/modal/terms-conditions';
import ResponsiveUtility from '../../components/common/utilities/responsive';
import { parameters } from '../../constants/parameters';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import UsersForgotPasswordPageComponent from '../users/forgot-password';
import UsersResetPasswordPageComponent from '../users/reset-password';
import NotFoundPageComponent from '../text/not-found';
import ProfileLayoutComponent from '../../components/profile/layout';
import UsersRegisterPageComponent from '../users/register';
import ArticlesMainFeedComponent from '../articles/main-feed';
import LayoutComponent from '../../components/layout/layout';
import ProfileSettingsPageComponent from '../profile/settings';
import UsersCompleteLoginPageComponent from '../users/complete-login';
import ArticleViewComponent from '../articles/view';
import CommentsShortComponent from '../comments/short';
import CommentsFullComponent from '../comments/full';
import ModalDeleteUser from '../../components/modal/delete-user';
import CommentsTeaserComponent from '../comments/teaser';
import AdminRolesUserComponent from '../admin/roles/user';
import AdminReportsComponent from '../admin/reports';
import ModalShareArticle from '../../components/modal/share-article';
import AdminRolesListComponent from '../admin/roles';
import AdminCommentsComponent from '../admin/comments';
import LayoutFullWidthComponent from '../../components/layout/full-width';
import ModalRegister from '../../components/modal/register-modal';
import ModalAnonymous from '../../components/modal/anonymous-modal';

export default function Root() {
  const { user, loading } = useAuth();

  const isLoggedIn = !loading && !!user;

  const element = useRoutes([
    {
      element: <LayoutFullWidthComponent />,
      children: [
        {
          path: '/admin/comments',
          element: isLoggedIn ? <AdminCommentsComponent /> : <Navigate to="/" />,
        },
      ],
    },
    {
      element: <LayoutComponent />,
      children: [
        {
          path: '/',
          element: <ArticlesMainFeedComponent />,
        },
        {
          path: '/articles/:slug/:categoryId',
          element: <ArticlesMainFeedComponent />,
        },
        {
          path: '/articles/view/:slug/:articleId',
          element: <ArticleViewComponent />,
        },
        {
          path: '/users',
          children: [
            {
              path: 'login',
              element: isLoggedIn ? <Navigate to="/" /> : <UsersLoginPageComponent />,
            },
            {
              path: 'register',
              element: isLoggedIn ? <Navigate to="/" /> : <UsersRegisterPageComponent />,
            },
            {
              path: 'forgot-password',
              element: <UsersForgotPasswordPageComponent />,
            },
            {
              path: 'complete-login',
              element: <UsersCompleteLoginPageComponent />,
            },
            {
              path: 'reset-password/:token',
              element: <UsersResetPasswordPageComponent />,
            },
            {
              path: 'profile',
              element: isLoggedIn ? <ProfileLayoutComponent /> : <Navigate to="/" />,
              children: [
                {
                  path: 'dashboard',
                  element: <ProfileDashboardPageComponent />,
                },
                {
                  path: 'settings',
                  element: <ProfileSettingsPageComponent />,
                },
              ],
            },
          ],
        },
        {
          path: '/admin',
          children: [
            {
              path: 'reports',
              element: isLoggedIn ? <AdminReportsComponent /> : <Navigate to="/" />,
            },
            {
              path: 'roles',
              children: [
                {
                  path: '',
                  element: isLoggedIn ? <AdminRolesListComponent /> : <Navigate to="/" />,
                },
                {
                  path: ':userId',
                  element: isLoggedIn ? <AdminRolesUserComponent /> : <Navigate to="/" />,
                },
              ],
            },
          ],
        },
        {
          path: '/privacy-policy',
          element: <TextPageComponent type="privacy" />,
        },
        {
          path: '/terms-conditions',
          element: <TextPageComponent type="terms" />,
        },
      ],
    },

    {
      path: '/comments',
      children: [
        {
          path: 'short/:portal/:articleId',
          element: <CommentsShortComponent />,
        },
        {
          path: 'full/:portal/:articleId',
          element: <CommentsFullComponent />,
        },
        {
          path: 'teaser/:portal/:articleId',
          element: <CommentsTeaserComponent />,
        },
      ],
    },
  ]);

  // {user && <div>User: {user?.email}</div>}

  return (
    <>
      {loading && <CommonAnimatedLoader />}
      {!loading && (element || <NotFoundPageComponent />)}

      <ModalPrivacyPolicy />
      <ModalTermsConditions />
      <ModalDeleteUser />
      <ModalShareArticle />
      {parameters.isDevelopment && <ResponsiveUtility />}
    </>
  );
}
