import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Select, { MultiValue } from 'react-select';
import tw, { styled } from 'twin.macro';

import ContainerMd from '../../../components/common/containers/containerMd';
import { strings } from '../../../localization/strings';
import { Customer } from '../../../entities/customer';
import { changeBlockStatus, getUser, updateUser } from '../../../services/api/users';
import { useAuth } from '../../../hooks/use-auth';
import CommonAnimatedSpinner from '../../../components/common/animated/spinner';
import { getCategories } from '../../../services/api/articles';
import { Category } from '../../../entities/category';
import Heading3 from '../../../components/common/headings/heading3';
import CommonButtonDefault from '../../../components/common/buttons/default';
import { getDateWithTime } from '../../../services/formatter/date';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import images from '../../../assets/images';

export default function AdminRolesUserComponent() {
  const { userId } = useParams<{ userId: string }>();
  const { token, user: currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState<Customer>();
  const [categories, setCategories] = useState<Category[]>([]);

  const TextGray = styled.p`
    ${tw`truncate text-sm text-gray-500`}
  `;

  const roles = Object.keys(strings.roles)
    .filter((item) => currentUser?.roles.indexOf(item) !== -1)
    .map((item) => ({
      id: item,
      title: strings.roles[item as keyof typeof strings.roles],
    }));

  useEffect(() => {
    const fetchUser = async (id: string) => {
      setIsLoading(true);

      const item = await getUser(token, id);
      const downloadedCategories = await getCategories();

      ReactDOM.unstable_batchedUpdates(() => {
        setIsLoading(false);
        setCategories(downloadedCategories);
        setUser(item);
      });
    };

    if (userId) {
      fetchUser(userId);
    }
  }, [userId]);

  const onSelectCategory = (category: MultiValue<Category>) => {
    if (user) {
      setUser({
        ...user,
        categoryIds: category.map((item) => item.id),
      });
    }
  };

  const onSelectRole = (role: { id: string; title: string }) => {
    if (user) {
      setUser({
        ...user,
        role: role.id,
      });
    }
  };

  const onSave = async () => {
    if (user) {
      setIsLoading(true);
      await updateUser(token, user.id, {
        role: user.role,
        categoryIds: user.categoryIds,
      });
      setIsLoading(false);
    }
  };

  const onBlockStateChange = async () => {
    if (user) {
      setIsLoading(true);
      await changeBlockStatus(token, user.id, user.isBlocked ? 0 : 1);
      setUser({
        ...user,
        isBlocked: user.isBlocked ? 0 : 1,
      });
      setIsLoading(false);
    }
  };

  const canChangeRole = () => {
    if (user?.id === currentUser?.id) {
      return false;
    }

    if (!user) {
      return false;
    }

    return currentUser?.roles.indexOf(user.role) !== -1;
  };

  return (
    <ContainerMd>
      <div className="p-5 bg-white rounded-xl">
        {isLoading && (
          <div className="py-5 flex align-middle justify-center">
            <CommonAnimatedSpinner />
          </div>
        )}

        {user && (
          <div>
            <div className="relative flex items-center space-x-3 pb-3">
              <div className="flex-shrink-0">
                {user?.imageUrl ? (
                  <img
                    className="shrink-0 grow-0 h-14 w-14 bg-gray-200 rounded-full object-cover"
                    src={user.imageUrl}
                    alt={user.firstName}
                  />
                ) : (
                  <img
                    src={images.AVATAR}
                    alt={user.firstName}
                    className="shrink-0 grow-0 h-14 w-14 bg-gray-200 rounded-full object-cover"
                  />
                )}
              </div>
              <div className="min-w-0 flex-1">
                <a href="#" className="focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900">
                    {user.firstName} {user.lastName}
                  </p>
                  <p className="text-sm font-medium text-gray-900">{user.email}</p>
                  <TextGray>
                    {strings.admin.role}: {user.role}
                  </TextGray>
                </a>
              </div>
            </div>
            <TextGray>
              {strings.admin.lastLogIn}:{' '}
              {(user.loggedAt && getDateWithTime(user.loggedAt)) || strings.admin.neverLogged}
            </TextGray>
            <TextGray>
              {strings.admin.ipAdress}: {user.loginIps?.join(',')}
            </TextGray>
            <TextGray>
              {strings.admin.lastUpdated}:{' '}
              {(user.updatedAt && getDateWithTime(user.updatedAt)) || strings.admin.neverUpdated}
            </TextGray>

            {!canChangeRole() ? (
              <div className="mt-5">
                <AlertPrimary text={strings.admin.cannotChangeRole} type="danger" />
              </div>
            ) : (
              <>
                <Heading3 className="mt-5 mb-2">{strings.admin.role}</Heading3>
                <div className="space-y-3">
                  <Select
                    isSearchable={false}
                    id="roles-picker"
                    options={roles}
                    className="react-select-container"
                    defaultValue={roles.find((option) => option.id === user.role)}
                    getOptionValue={(option) => `${option.id}`}
                    getOptionLabel={(option) => `${option.title}`}
                    onChange={(e) => {
                      if (e) {
                        onSelectRole(e);
                      }
                    }}
                  />

                  <Heading3 className="mt-5 mb-2">{strings.admin.categories}</Heading3>
                  <Select
                    isSearchable={false}
                    isMulti
                    isClearable
                    id="categories-picker"
                    options={categories}
                    className="react-select-container"
                    defaultValue={categories.filter((option) => {
                      if (!user.categoryIds) {
                        return false;
                      }

                      return user.categoryIds && user.categoryIds.indexOf(option.id) !== -1;
                    })}
                    getOptionValue={(option) => `${option.id}`}
                    getOptionLabel={(option) => `${option.title}`}
                    onChange={(e) => {
                      if (e) {
                        onSelectCategory(e);
                      }
                    }}
                  />
                </div>
                <div className="mt-4 flex space-x-4 justify-between">
                  <CommonButtonDefault primary onClick={onSave} type="button">
                    <span>{strings.button.save}</span>
                  </CommonButtonDefault>
                  {user.isBlocked ? (
                    <CommonButtonDefault onClick={onBlockStateChange} type="button">
                      <span>{strings.button.unblock}</span>
                    </CommonButtonDefault>
                  ) : (
                    <CommonButtonDefault onClick={onBlockStateChange} type="button" red>
                      <span>{strings.button.block}</span>
                    </CommonButtonDefault>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </ContainerMd>
  );
}
